import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Grid,
  Typography,
  Autocomplete,
  CircularProgress,
  Button,
  TextField,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Link,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import {
  Constraints,
  OPERATOR_ARRAY,
  RegisterMobileDes,
} from "../../../../constant/Analyst";
import { Style } from "../../Style";
import { getGridName, isOptionEqualToValue } from "../../Utils";
import { storeConnector } from "../../../../store";
import { ThemeContext } from "../../../Common/Theme/Context";

const CreateAlert = ({
  handleClose,
  open,
  onCreate,
  actions,
  handelPhoneVerify,
  email,
  user_country,
  user_phone_number,
  userCredit,
}) => {
  const { theme } = useContext(ThemeContext);

  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isShowLink, setIsShowLink] = useState(false);
  const [isShowPhone, setIsShowPhone] = useState(false);

  const modalRef = useRef();

  const streams = useSelector((state) => state.alert.streams);
  const getGridLocations = useSelector((state) => state.alert.gridLocations);

  const handleOutsideClick = useCallback(
    (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    },
    [modalRef, handleClose]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const formik = useFormik({
    initialValues: {
      name: "",
      stream: "",
      nodes: "",
      condition: "",
      price: "",
      email: false,
      sms: false,
    },
    validate: (values) => {
      const errors = {};
      if (formik.submitCount >= 0 && values.name === "") {
        errors.name = Constraints.requireName;
      }
      if (formik.submitCount >= 0 && values.name.length > 15) {
        errors.name = Constraints.requireNameChar;
      }
      if (formik.submitCount >= 0 && values.stream === "") {
        errors.stream = Constraints.requireStream;
      }
      if (formik.submitCount >= 0 && selectedStreamObj?.nodeSelection && values.nodes === "") {
        errors.nodes = Constraints.requireGridLocation;
      }
      if (formik.submitCount >= 0 && values.condition === "") {
        errors.condition = Constraints.requireCondition;
      }
      if (formik.submitCount >= 0 && values.price === "") {
        errors.price = Constraints.requirePrice;
      }
      return errors;
    },

    onSubmit: (values) => {
      setIsSaving(true);
      setLoading(true);

      onCreate({
        chartType: "AlertTable",
        streamName: values.stream?.value,
        gridName: values.nodes,
        payload: [
          {
            stream: values.stream?.value,
            nodes: [values.nodes],
            name: values.name,
            condition: values.condition,
            price: values.price,
          },
        ],
        name: values.name,
        condition: values.condition,
        price: values.price,
        email: values.email,
        sms: values.sms || false,
        emailId: email || "",
        country: user_country || "",
        phoneNumber: user_phone_number || "",
        userCredit: userCredit,
      });
    },
  });

  const streamNames = streams?.map((stream, index) => ({
    label: stream?.title,
    value: stream?.name,
    key: `${stream?.title}-${index}`,
  }));

  const gridLocations = useMemo(() => {
    return formik.values.stream
      ? getGridLocations?.map((item) => ({ label: item, value: item }))
      : [];
  }, [formik.values.stream, getGridLocations]);

  const selectedStreamObj = streams?.find((stream) =>
    stream.name === formik.values.stream?.label
  );

  const fecthGridLocation = useCallback(
    (selectedStream, actions, setLoading) => {
      if (!selectedStream) return null;
      if (selectedStream) {
        setLoading(true);
        actions.alert
          .getGridLocations({ stream: selectedStream?.value })
          .then((res) => {
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log("Error fetching grid locations:", err);
            return null;
          });
      }
    },
    []
  );

  useEffect(() => {
    if (
      formik.values.stream &&
      selectedStreamObj?.nodeSelection !== false
    ) {
      fecthGridLocation(formik.values.stream, actions, setLoading);
    }
  }, [fecthGridLocation, actions, formik.values.stream, selectedStreamObj]);

  useEffect(() => {
    if (formik.values.sms) {
      if (user_phone_number) {
        setIsShowPhone(true);
      } else {
        setIsShowLink(true);
      }
    } else {
      setIsShowLink(false);
      setIsShowPhone(false);
    }
  }, [formik.values.sms, user_phone_number]);

  return (
    <Box
      style={{
        display: open ? "block" : "none",
      }}
      sx={Style.chart.modelBox}
    >
      <Grid
        ref={modalRef}
        container
        className="alert-modal-container"
        justifyContent="center"
        alignItems="center"
        style={{
          display: open ? "block" : "none",
          height: loading ? "auto" : "95%",
        }}
        sx={[
          Style.chart.modelGrid,
          { background: theme === "light" ? "#fff" : "#1a1a1a" },
        ]}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            backgroundColor: "var(--peri-color)",
            lineHeight: "normal",
            fontSize: "17px",
            fontWeight: "700",
            borderRadius: "5px 5px 0px 0px",
            textAlign: "left",
          }}
        >
          {Constraints.createAlert}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{
              position: "absolute",
              top: "5px",
              right: "8px",
              width: "auto",
            }}
          >
            {loading ? " " : <CloseIcon />}
          </IconButton>
        </DialogTitle>
        <Box style={{overflowY: "scroll", height: "90%" }}>
          <DialogContent>
            <Box id="alert-dialog-description">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      margin: "10px 0",
                    }}
                  >
                    {streams?.length === 0 || loading ? (
                      <Grid
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "50px",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress style={{ color: "var(--text-color)" }} />
                      </Grid>
                    ) : (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            sx={{
                              fontWeight: "600",
                            }}
                          >
                            {Constraints.name}
                          </Typography>
                          <TextField
                            id="name"
                            name="name"
                            variant="outlined"
                            sx={Style.createAlert.textFieldStyle}
                            onChange={formik.handleChange}
                            value={formik.values.name}
                          />
                          {formik.errors.name && formik.touched.name && (
                            <div style={{ color: "red" }}>
                              {formik.errors.name}
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            sx={{
                              fontWeight: "600",
                            }}
                          >
                            {Constraints.selectStream}
                          </Typography>
                          <Autocomplete
                            disablePortal
                            options={streamNames}
                            value={formik.values.stream || null}
                            onChange={(e, v) => {
                              formik.setFieldValue('stream', v ?? '');
                              formik.setFieldValue('nodes', '');
                            }}
                            fullWidth
                            renderInput={(params) => <TextField {...params} />}
                            renderOption={(props, option) => (
                              <li {...props} key={option.key}>
                                {option.label}
                              </li>
                            )}
                          />
                          {formik.errors.stream && formik.touched.stream && (
                            <div style={{ color: "red" }}>
                              {formik.errors.stream}
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          {selectedStreamObj?.nodeSelection !== false && (
                              <>
                                <Typography
                                  variant="subtitle2"
                                  gutterBottom
                                  sx={{
                                    fontWeight: "600",
                                  }}
                                >
                                  {Constraints.selectGridLocation}
                                </Typography>
                                <Autocomplete
                                  disablePortal
                                  limitTags={1}
                                  options={gridLocations}
                                  value={getGridName(
                                    formik.values.nodes,
                                    gridLocations
                                  )}
                                  onChange={(e, v) => {
                                    formik.setFieldValue(
                                      "nodes",
                                      v ? v?.value : ""
                                    );
                                  }}
                                  fullWidth
                                  isOptionEqualToValue={isOptionEqualToValue}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                                {formik.errors.nodes && formik.touched.nodes && (
                                  <div style={{ color: "red" }}>
                                    {formik.errors.nodes}
                                  </div>
                                )}
                              </>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            sx={{
                              fontWeight: "600",
                            }}
                          >
                            {Constraints.selectCondition}
                          </Typography>
                          <Autocomplete
                            disablePortal
                            options={OPERATOR_ARRAY}
                            value={formik.values.condition || null} // Provide the selected options as value
                            onChange={(e, v) => {
                              formik.setFieldValue("condition", v ?? "");
                            }}
                            fullWidth
                            renderInput={(params) => <TextField {...params} />}
                          />
                          {formik.errors.condition &&
                            formik.touched.condition && (
                              <div style={{ color: "red" }}>
                                {formik.errors.condition}
                              </div>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            sx={{
                              fontWeight: "600",
                            }}
                          >
                            {Constraints.value}
                          </Typography>
                          <TextField
                            sx={Style.createAlert.textFieldStyle}
                            value={formik.values.price}
                            onChange={(event) => {
                              const inputValue = event.target.value;
                              if (!isNaN(inputValue)) {
                                formik.setFieldValue("price", inputValue);
                              } else {
                                console.log("Invalid number: ", inputValue);
                              }
                            }}
                          />
                          {formik.errors.price && formik.touched.price && (
                            <div style={{ color: "red" }}>
                              {formik.errors.price}
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            sx={{
                              fontWeight: "600",
                            }}
                          >
                            {Constraints.notification}
                          </Typography>
                          <Box>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formik.values.email}
                                  onChange={() => {
                                    formik.setFieldValue(
                                      "email",
                                      !formik.values.email
                                    );
                                  }}
                                />
                              }
                              disabled
                              label={`${Constraints.email} (Coming soon)`}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formik.values.sms}
                                  onChange={() => {
                                    formik.setFieldValue(
                                      "sms",
                                      !formik.values.sms
                                    );
                                  }}
                                />
                              }
                              label={Constraints.sms}
                            />
                          </Box>
                        </Grid>
                        {isShowLink && (
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle1"
                              gutterBottom
                              sx={{
                                fontWeight: "300",
                              }}
                            >
                              {Constraints.mobileNumberVerify}
                              <Link
                                component="button"
                                variant="subtitle1"
                                className={"btn-link"}
                                style={{
                                  color: "var(--peri-color)",
                                  textDecoration: "none",
                                  top: -1,
                                  marginLeft: 5,
                                  width: "auto",
                                }}
                                onClick={() => {
                                  handelPhoneVerify();
                                }}
                              >
                                click here.
                              </Link>
                            </Typography>
                          </Grid>
                        )}
                        {isShowPhone && (
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle1"
                              gutterBottom
                              className={"btn-link"}
                              sx={{
                                fontWeight: "300",
                                color: "var(--peri-color)",
                                fontSize: "15px",
                              }}
                            >
                              {RegisterMobileDes(user_phone_number)}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              padding: "20px 24px",
            }}
          >
            <Button
              onClick={formik.handleSubmit}
              disabled={isSaving}
              autoFocus
              sx={{
                background: "var(--peri-color)",
                fontWeight: "normal",
                textTransform: "none",
                padding: "8px 30px",
                width: "auto",
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Box>
      </Grid>
    </Box>
  );
};

CreateAlert.propTypes = {
  onCreate: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  email: PropTypes.string,
  actions: PropTypes.any,
  handelPhoneVerify: PropTypes.func,
  user_country: PropTypes.string,
  user_phone_number: PropTypes.string,
  streams: PropTypes.array,
  userCredit: PropTypes.number,
};

export default storeConnector(CreateAlert, {
  service: "all",
  auth: ["email", "userId"],
  alert: [
    "alertList",
    "alertLoading",
    "streams",
    "user_country",
    "userCredit",
  ],
  user: ["user_phone_number"]
});
