import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { AlertMenu, IconButtons } from "../../AlertMenu";
import { Constraints, IconColor } from "../../../../constant/Analyst";
import { IconButtonStyle } from "../../Style";
import { EmailIcon, SmsIcon } from "../../../Common/SvgIcons";
import { ThemeContext } from "../../../Common/Theme/Context";
import "./Card.css";

function formatString(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

function Index({
  alert,
  CapitalizeText,
  getStreamName,
  setIsEdit,
  setIsDelete,
  streams,
}) {
  const { theme } = useContext(ThemeContext);
  const optionsRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const [active, setActive] = useState(false);

  const handleMoreChart = (event, chartid) => {
    setIsMenuOpen(true);
    setIsMenuClicked(chartid);
  };

  /**
   * The `cardHeight` function determines the height of a cardContainer element
   * based on the state of two variables: `active` and `alert.sms`.
   * It returns a height value as a string with the unit 'px' included,
   * ensuring it can be directly used in CSS styling.
   * */
  const cardHeight = () => {
    if (active) {
      if (alert?.sms) {
        return "182px";
      } else {
        return "140px";
      }
    } else {
      return "40px";
    }
  };

  let emailIcon = null;
  let smsIcon = null;

  // Check if alert and email exist
  if (alert?.email) {
    // Check if emailAlert is PAUSED or ACTIVE
    if (alert?.emailAlert === Constraints.PAUSED) {
      emailIcon = (
        <EmailIcon
          color={IconColor.gray}
          btnClick={() => {
            setIsEdit({ id: alert.id, title: "Alert" });
          }}
        />
      );
    } else if (alert?.emailAlert === Constraints.ACTIVE) {
      emailIcon = (
        <EmailIcon
          color={IconColor.green}
          btnClick={() => {
            setIsEdit({ id: alert.id, title: "Alert" });
          }}
        />
      );
    }
  }

  // Check if alert and sms exist
  if (alert?.sms) {
    // Check if textAlert is PAUSED or ACTIVE
    if (alert?.textAlert === Constraints.PAUSED) {
      smsIcon = (
        <SmsIcon
          color={IconColor.gray}
          btnClick={() => {
            setIsEdit({ id: alert.id, title: "Alert" });
          }}
        />
      );
    } else if (alert?.textAlert === Constraints.ACTIVE) {
      smsIcon = (
        <SmsIcon
          color={IconColor.green}
          btnClick={() => {
            setIsEdit({ id: alert.id, title: "Alert" });
          }}
        />
      );
    }
  }

  return (
    <div
      id="cardContainer"
      style={{
        height: cardHeight(),
        transition: "0.3s",
      }}
    >
      <div
        id="firstDisplay"
        style={{
          borderBottomLeftRadius: active ? "0px" : "8px",
          borderBottomRightRadius: active ? "0px" : "8px",
          border:
            theme === "light" ? "" : "1px solid var(--chart-border-color)",
        }}
      >
        <Box sx={IconButtonStyle.alertTable.sideMenuBox}>
          <AlertMenu
            handleDelete={() => {
              setIsDelete({ id: alert.id, title: "Alert" });
              setIsMenuOpen(false);
              setActive(false);
            }}
            handleEdit={() => {
              setIsEdit({ id: alert.id, title: "Alert" });
              setIsMenuOpen(false);
            }}
            setMenu={setIsMenuClicked}
            isMenu={isMenuClicked}
            optionsRef={optionsRef}
            open={isMenuOpen}
            setOpen={setIsMenuOpen}
          />
          <Box
            style={IconButtonStyle.alertTable.flexBox}
            onClick={() => {
              setActive(!active);
            }}
          >
            {active ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}

            <Typography
              style={IconButtonStyle.alertTable.sideMenuBoxText}
              sx={{ color: "var(--text-color)" }}
            >
              {CapitalizeText(alert.alertTitle)}
            </Typography>
          </Box>
          <Box style={IconButtonStyle.alertTable.iconBoxFlex}>
            {emailIcon}
            {smsIcon}

            <IconButtons
              data={alert.id}
              handleMoreChart={handleMoreChart}
              open={isMenuOpen}
              setOpen={setIsMenuOpen}
              optionsRef={optionsRef}
            />
          </Box>
        </Box>
      </div>
      <div
        id="first"
        style={{
          transform: active
            ? `rotate3d(1, 0, 0, -180deg)`
            : `rotate3d(1, 0, 0, 0deg)`,
        }}
      >
        <div
          id="firstTop"
          style={{
            background: "var(--background-color)",
            border: "1px solid var(--chart-border-color)",
          }}
        >
          <Box
            sx={[
              IconButtonStyle.alertTable.sideMenuBox,
              { color: theme === "light" ? "" : "#fff" },
            ]}
          >
            <Box
              style={IconButtonStyle.alertTable.flexBox}
              onClick={() => {
                setActive(!active);
              }}
            >
              {active ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}

              <Typography
                style={IconButtonStyle.alertTable.sideMenuBoxText}
                sx={{ color: "var(--text-color)" }}
              >
                {CapitalizeText(alert.alertTitle)}
              </Typography>
            </Box>
            <Box style={IconButtonStyle.alertTable.iconBoxFlex}>
              {emailIcon}
              {smsIcon}

              <IconButtons
                data={alert.id}
                handleMoreChart={handleMoreChart}
                open={isMenuOpen}
                setOpen={setIsMenuOpen}
                optionsRef={optionsRef}
              />
            </Box>
          </Box>
        </div>
        <div id="firstBehind">
          <div
            id="firstBehindDisplay"
            style={{
              background: "var(--background-color)",
              border:
                theme === "light" ? "" : "1px solid var(--chart-border-color)",
            }}
          >
            <div id="firstBehindRow">
              <div id="detail">
                {Constraints.stream}
                <div id="detailLabel">
                  {getStreamName(alert.streamName, alert.gridName, streams) ?? '-'}
                </div>
              </div>
              <div id="detail">
                {Constraints.grid}
                <div id="detailLabel">
                  {alert.gridName || "-"}
                </div>
              </div>
            </div>
            <div id="firstBehindRow">
              <div id="detail">
                {Constraints.condition}
                <div id="detailLabel">{alert.condition}</div>
              </div>
              <div id="detail">
                {Constraints.value}
                <div id="detailLabel">{alert.alertPrice}</div>
              </div>
            </div>
          </div>
          <div
            id="second"
            style={{
              transform: active
                ? `rotate3d(1, 0, 0, -180deg)`
                : `rotate3d(1, 0, 0, 0deg)`,
            }}
          >
            <div id="secondTop" />
            {alert?.sms && (
              <div id="secondBehind">
                <div id="secondBehindDisplay" 
                  style={{ border: theme === "light" ? "" : "1px solid var(--chart-border-color)"}}
                >
                  <Box style={IconButtonStyle.alertTable.descriptionTagBox}>
                    <Typography variant="subtitle2" id="detail">
                      {Constraints.smsAlert}
                    </Typography>
                    <Typography
                      style={
                        alert?.textAlert !== Constraints.PAUSED
                          ? IconButtonStyle.alertTable.descriptionTagActive
                          : IconButtonStyle.alertTable.descriptionTagPaused
                      }
                    >
                      {alert?.textAlert === Constraints.PAUSED ? (
                        <NotificationsPausedIcon sx={{ fontSize: "18px" }} />
                      ) : (
                        <NotificationsActiveIcon sx={{ fontSize: "18px" }} />
                      )}
                      &nbsp;
                      {formatString(alert?.textAlert)}
                    </Typography>
                  </Box>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

Index.propTypes = {
  alert: PropTypes.object.isRequired,
  CapitalizeText: PropTypes.func.isRequired,
  getStreamName: PropTypes.func.isRequired,
  setIsEdit: PropTypes.func.isRequired,
  setIsDelete: PropTypes.func.isRequired,
  streams: PropTypes.array,
};

export default Index;
