export const ROUTES = {
  LOGIN: "/login",
  LOGOUT: "/logout",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
};

export const PRIVATE_ROUTES = {
  PAGENOTFOUND: "*",
  HOME: "/",
  REPORT: "/report",
  REPORT_UPLOAD: "/report/upload",
  ANALYST: "/alerts",
};

export const ROUTE_TEXTS = {
  HOME: 'Home',
  REPORT: 'Report',
  ALERT: 'Alerts',
  LOGOUT: 'Logout'
}

export const PUBLIC_URLS = Object.values(ROUTES);

export const match_urls = (pathname)=> {
  return  PUBLIC_URLS.find((item)=> {
    return pathname.toLowerCase().indexOf(item.toLowerCase()) !== -1 ?  true : false;
  })
}



console.log('PUBLIC_URLS',PUBLIC_URLS)
