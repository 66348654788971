import { bindActionCreators } from "redux";
import alertActions from "./AlertAction";
import authActions from "./authAction";
import * as serviceActions from './serviceActions';
import * as userActions from "./userActions";
// import notifyActions from "./notifyActions";

const actions = (dispatch) => ({
  alert: bindActionCreators(alertActions, dispatch),
  auth: bindActionCreators(authActions, dispatch),
  service: bindActionCreators(serviceActions, dispatch),
  user: bindActionCreators(userActions, dispatch),
  // notify: bindActionCreators(notifyActions, dispatch),
});

export default actions;
