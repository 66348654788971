import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faStop } from "@fortawesome/free-solid-svg-icons";

const PlayStopButton = ({ isPlaying, onPlay, onStop }) => {
  const handleClick = () => {
    if (isPlaying) {
      onStop();
    } else {
      onPlay();
    }
  };

  return (
    <button onClick={handleClick} className="play-stop-button">
      <FontAwesomeIcon icon={isPlaying ? faStop : faPlay} size="sm" />
    </button>
  );
};

export default PlayStopButton;
