import axios from "axios";
import { BACKEND_API } from "../config";

export const httpBase = (token) => {
  const api = axios.create({
    baseURL: `${BACKEND_API}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    responseType: "json",
  });

  api.interceptors.request.use((request) => {
    return request;
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // Ensuring that the rejection reason is an instance of Error
      const errorMessage =
        error?.response?.data || error.message || "An error occurred";
      const rejectionError = new Error(errorMessage);
      return Promise.reject(rejectionError);
    }
  );

  return api;
};
