export const RESET = "Reset";
export const BACK = "Back";
export const SUBMIT = "Submit";
export const RESET_PASSWORD = "Reset password";
export const FORGOT_PASSWORD = "Forgot password";
export const RESET_PASSWORD_ERROR = 'Password reset error';
export const SIGN_UP = "Sign Up";
export const SUPPORT_EMAIL = "support@fractalmodel.com";
export const CHANGE_PASSWORD = "Password was successfully changed";
export const CREATE_PASSWORD = "Create a new password";
export const GO_TO_HOME = "Go to Home page";
export const GO_TO_SIGN_IN = "Go to Sign In";
export const EMAIL = "Email";
export const PASSWORD = "Password";
export const EMAIL_RESET_LINK =
  "Please check your email for a reset link and follow instructions";

export const LOGIN_ERROR = {
  userNotExist: `Can't sign in: User does not exist.`,
  login: "Successfully Logged In",
  wrongConfirmation: "Wrong confirmation URL, please click on link from email",
  validOTP: "Please enter a valid OTP",
};
