import { combineReducers } from "redux";
import alertReducers from "./AlertReducer";
import authReducer from './authReducer';
import notifyreducer from './notifyreducer';
import serviceReducer from './serviceReducer';
import userReducers from './userReducers';

const reducers = combineReducers({
  alert: alertReducers,
  auth: authReducer,
  notifyreducer,
  service: serviceReducer,
  user: userReducers
});

export default reducers;
