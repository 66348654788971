import React, { useState, useMemo, useCallback,  useRef, useEffect } from "react";
import PropType from "prop-types";
import OtpInput from "react-otp-input";
import {
  Box,
  Grid,
  Typography,
  Autocomplete,
  CircularProgress,
  Button,
  TextField,
  IconButton,
  DialogTitle,
  DialogContent,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  Constraints,
  PhoneConstants,
  VerifyOTPConstants,
  codeDetailsWithNumber,
} from "../../../constant/Analyst";
import { Style } from "../Style";
import { storeConnector } from "../../../store";
import { COUNTRY_NAME_CODE } from "../../../constant/Country";

const AddPhone = ({
  handleClose,
  open,
  update,
  actions,
  username,
  userId,
  email,
  user_deciphered_number,
  user_country
}) => {

  const [loading, setLoading] = useState(false);
  const modalRef = useRef();
  const [phone, setPhone] = useState(user_deciphered_number);
  const [selectedCountry, setSelectedCountry] = useState({
    label: "United States",
    value: "+1",
  });
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);
  const [otpError, setOtpError] = useState(false);

  const CountryData = useMemo(() => {
    const data = COUNTRY_NAME_CODE?.map((country) => {
      return {
        label: country.name,
        value: country.dial_code,
      };
    });
    return data;
  }, []);

  const handleOutsideClick = useCallback(
    (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    },
    [modalRef, handleClose]
  );
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);



  const updatePhoneNumber = async () => {
    const num = selectedCountry?.value + phone;

    if (userId) {

       if (user_deciphered_number){
            actions.user
            .userPhoneNumberAdd({
              userid: userId,
              phoneNumber: num,
              code: selectedCountry?.label || "United States",
            })
            .then((res) => {})
            .catch((err) => {
              console.log("err: ", err);
            });
       } else {
          actions.user
          .updateCreditAdd({
            userid: userId,
            phoneNumber: num,
            emailId: email,
            code: selectedCountry?.label || "United States",
          })
          .then((res) => {})
          .catch((err) => {
            console.log("err: ", err);
          });
       }
     
    }
  };

  /**
   * @desc Sends a one-time password (OTP) to the user's phone for verification.
   * @desc Updates the phone verification status and clears the anchor element.
   * @param {Object} values - The values needed to send the OTP.
   */
  const sendPhoneOTP = async (values) => {
    try {
      await actions.alert.sendPhoneOTP(values).then((result) => {
        if (result.status === 200) {
          setTimeout(() => {
            setLoading(false);
            update(2);
          }, 2000);
        } else if (result.status === 422) {
          setError(PhoneConstants.setError.invalid);
          setLoading(false);
        } else {
          setError(PhoneConstants.setError.internal);
          setLoading(false);
        }
      });
    } catch (err) {
      setError(PhoneConstants.setError.internal);
      console.error(err);
      setLoading(false);
    }
  };

  /**
   * @desc Verifies the phone OTP and updates the chart if necessary.
   * @param {Object} values - The values needed to verify the OTP.
   */
  const verifyPhoneOTP = async (values) => {
    try {
      await actions.alert.verifyPhoneOTP(values).then((result) => {
        console.log('result',result);
        if (result.status === 200) {
          updatePhoneNumber();
          setTimeout(() => {
            setLoading(false);
            handleClose();
            actions.user
              .getUserDetails({ userid: userId })
              .then((res) => {})
              .catch((err) => {
                console.log("verfity phone number error", err);
              });
            actions.service.notify(VerifyOTPConstants.setNotify.success);
          }, 2000);
        } else if (result.status === 401) {
          setOtpError(VerifyOTPConstants.setError.invalid);
        
        } else if (result.status === 422) {
          setOtpError(VerifyOTPConstants.setError.invalid);
          
        } else {
          setOtpError(VerifyOTPConstants.setError.internal);
          
        }
        setLoading(false);
      }).catch((err)=>{
        setOtpError(VerifyOTPConstants.setError.invalid);
        setLoading(false);
      });
    } catch (err) {
      setOtpError(VerifyOTPConstants.setError.internal);
      setLoading(false);
      console.error(err);
    }
  };

  const handleCountryChange = (event, value) => {
    setSelectedCountry(value);
  };
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    const isValidInput = /^\d*$/.test(value);
    if (isValidInput) {
      setPhone(value);
    }
  };

  const handelSendOTP = () => {
    setLoading(true);
    if (!!selectedCountry && !!phone) {
      const value = {
        countryCode: selectedCountry.value,
        toPhoneNumber: phone,
      };
      sendPhoneOTP(value);
    } else {
      setError(PhoneConstants.setError.validate);
      setLoading(false);
    }
  };
  const handelVerifyOTP = () => {
    setLoading(true);
    if (otp.length === 6) {
      const value = {
        countryCode: selectedCountry.value,
        toPhoneNumber: phone,
        verificationCode: otp,
      };
      verifyPhoneOTP(value);
    } else {
      setOtpError(PhoneConstants.setError.validate);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if(user_country) {
      const values = COUNTRY_NAME_CODE?.find((item) => item.name === user_country)
      if(values){
        setSelectedCountry({
          label: values?.name,
          value: values?.dial_code,
        })
      }
     
    }
  }, [user_country]);

  return (
    <Box
      style={{
        display: open ? "block" : "none",
      }}
      sx={Style.chart.modelBox}
    >
      <Grid
        ref={modalRef}
        container
        className="alert-modal-container"
        justifyContent="center"
        alignItems="center"
        style={{
          display: open ? "block" : "none",
        }}
        sx={[
          Style.chart.modelGridOTP,
          { background: "var(--background-color)" },
        ]}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            backgroundColor: "var(--peri-color)",
            lineHeight: "normal",
            fontSize: "17px",
            fontWeight: "700",
            borderRadius: "5px 5px 0px 0px",
            textAlign: "left"
          }}
        >
          {open === 1
            ? Constraints.addPhoneNumber
            : Constraints.otpVerification}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{
              position: "absolute",
              top: "5px",
              right: "8px",
              width: "auto",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box id="alert-dialog-description">
            <Grid container spacing={2}>
              {open === 1 && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      margin: "10px 0",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Typography
                          variant="h5"
                          gutterBottom
                          sx={{
                            fontWeight: "600",
                          }}
                        >
                          {Constraints.verifyNumber}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ display: "flex" }}>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          sx={{
                            fontWeight: "600",
                          }}
                        >
                          {Constraints.otpDetails}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Autocomplete
                          disablePortal
                          options={CountryData}
                          value={selectedCountry || null}
                          onChange={handleCountryChange}
                          fullWidth
                          renderInput={(params) => (
                            <TextField {...params} label={"Country"} />
                          )}
                        />
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          id="number"
                          name="number"
                          label="Phone number"
                          variant="outlined"
                          sx={Style.createAlert.textFieldStyle}
                          InputProps={{
                            startAdornment: selectedCountry ? (
                              <InputAdornment position="start">
                                {selectedCountry.value}
                              </InputAdornment>
                            ) : null,
                          }}
                          onChange={handlePhoneChange}
                          value={phone}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              handelSendOTP();
                            }
                          }}
                        />
                      </Grid>
                      {error && (
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "30px",
                          }}
                        >
                          <div style={{ color: "red" }}>{error}</div>
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "30px",
                        }}
                      >
                        <Button
                          onClick={handelSendOTP}
                          disabled={loading}
                          autoFocus
                          sx={{
                            background: "var(--peri-color)",
                            textTransform: "none",
                            padding: "14px 30px",
                            fontWeight: "bold",
                            width: "100%",
                            "&:hover": {
                              backgroundColor: "var(--blue-violet-color)",
                            },
                          }}
                        >
                          {Constraints.sendOTP} &nbsp;&nbsp;
                          {loading ? (
                            <CircularProgress
                              style={{ width: 24, height: 24, color: "var(--text-color)" }}
                            />
                          ) : (
                            <SendIcon />
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )}
              {open === 2 && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      margin: "10px 0",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Typography
                          variant="h5"
                          gutterBottom
                          sx={{
                            fontWeight: "600",
                          }}
                        >
                          {Constraints.verifyCode}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          sx={{
                            fontWeight: "600",
                          }}
                        >
                          {codeDetailsWithNumber(
                            selectedCountry?.value + phone
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <OtpInput
                          value={otp}
                          style={{
                            width: "100%",
                          }}
                          containerStyle={{
                            height: 60,
                            justifyContent: "center",
                            marginBottom: 20,
                          }}
                          inputStyle={{
                            height: 40,
                            width: 40,
                            fontSize: 20,
                            marginRight: 5,
                            marginLeft: 5,
                            borderRadius: 10,
                            border: "1px solid var(--black-color)",
                          }}
                          onChange={setOtp}
                          numInputs={6}
                          shouldAutoFocus={true}
                          renderSeparator={<span></span>}
                          renderInput={(props) => <input {...props} 
                            onKeyDown={(event) => {
                              if (event.key === "Enter" && otp !== '') {
                                handelVerifyOTP();
                              }
                            }}
                          />}
                        />
                      </Grid>
                      {otpError && (
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <div style={{ color: "red" }}>{otpError}</div>
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          onClick={handelVerifyOTP}
                          disabled={loading}
                          autoFocus
                          sx={{
                            background: "var(--peri-color)",
                            textTransform: "none",
                            padding: "14px 30px",
                            fontWeight: "bold",
                            width: "70%",
                            "&:hover": {
                              backgroundColor: "var(--blue-violet-color)",
                            },
                          }}
                        >
                          {Constraints.verifyOTP} &nbsp;&nbsp;
                          {loading ? (
                            <CircularProgress
                              style={{ width: 24, height: 24, color: "var(--text-color)" }}
                            />
                          ) : (
                            <VerifiedIcon />
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </DialogContent>
      </Grid>
    </Box>
  );
};

AddPhone.propTypes = {
  handleClose: PropType.func.isRequired,
  open: PropType.number,
  update: PropType.func,
  actions: PropType.any,
  username: PropType.string,
  userId: PropType.string,
  email: PropType.string,
  user_deciphered_number: PropType.string,
};

export default storeConnector(AddPhone, {
  service: "all",
  auth: ["userId", "email"],
  user: ["user_deciphered_number", 'user_country'],
});
