import React, { useContext,useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from "../../../constant/routes"
import { Button } from "../index";
import { ThemeContext } from "../Theme/Context";

import {
  
    GO_TO_SIGN_IN,
 
  } from "../../../constant/Login"
import { LoginWrapper } from "../Layouts/Layout"
/**
* This component is used to display the home page when an route error occurs. 
  In this case the user will be redirected to the home page and a button will be displayed to go to the home page.
* @return { JSX. Element } The index component to be rendered on the page
*/
const Page404 = ()  => {

  const [loading,setLoader] = useState(false)
  useEffect(() => {

    const time = setTimeout(()=>{
        setLoader(false);
    },2000)

    return (
      clearTimeout(time)
    )

  },[])

  const { theme } = useContext(ThemeContext); 
  const history = useHistory();

  if(loading){
    return (<div></div>)
  }
  return (
    <LoginWrapper loading={null} theme={theme}>
   
        <div
          className="login-form-column centered"
          style={{ minWidth: "unset" }}
        >
          <div className="title with-underline">
            <h1>
              <span>ERROR 404</span>
            </h1>
          </div>
          <div className="info" style={{ textAlign: "center" }}>
            <h3>Page not Found!</h3>
            <div className="login-container btn-container">
              <Button
                containerClassName=""
                onClick={() => {
                  history.push(ROUTES.LOGIN);
                  localStorage.removeItem("group");
                }}
              >
                {GO_TO_SIGN_IN}
              </Button>
            </div>
          </div>
        </div>
  
      
    
    </LoginWrapper>
  );
}

export default Page404;




