import React from "react";
import PropTypes from "prop-types";

const Index = ({ classNames, title }) => {
  return <h2 className={classNames}>{title}</h2>;
};

Index.propTypes = {
  classNames: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Index;
