import { userPool } from '../../services';


const authReducer = (
  state = {
    cognitoUser: userPool.getCurrentUser(),
    loginError: false,
    userIsLogin: false,
    jwtToken: false,
    group: false,
    email: false,
    authJwtToken: false,
    authLoading: false,
    userId: false,
    username: false,
    email_verified: false,
   
  },
  action
) => {
  const handlers = {
    LOGIN_SUCCESS: () => {
      const newState = {
        ...state
      };
      const { payload, jwtToken } = action.userdata.getIdToken();
      console.log('payload', payload)
      newState.userIsLogin = true;
      newState.jwtToken = jwtToken;
      const groups = payload['cognito:groups'];
      newState.group = groups && groups[0] ? groups[0] : "Viewer";
      newState.username = payload['cognito:username'];
      newState.email = payload.email;
      newState.email_verified = payload['email_verified'];
      newState.userId = payload['sub'];
      newState.name = payload['name'];
      newState.loginError = false;
      localStorage.removeItem('token');
      const idToken = action.userdata.getIdToken();
      window.user = idToken && idToken.payload;

      return newState;
    },
    AUTH_LOADING: () => ({
      ...state,
      authLoading: action.authLoading
    }),
    REFRESH_USER: () => {
      const newState = {
        ...state
      };
      const groups = action.userdata.getIdToken().payload['cognito:groups'];
      newState.group = groups && groups[0] ? groups[0] : false;
    },
 
    LOGOUT: () => {
      const newState = {
        ...state
      };
      localStorage.removeItem('group');
      newState.loginError = false;
      newState.email_verified = false;
      newState.authLoading = false;
      newState.authJwtToken = false;
      newState.group = false;
      newState.username = false;
      newState.email = false;
      newState.phone_number = false;
      newState.userId = false;

      return newState;
    },
    LOGIN_ERROR: () => ({
      ...state,
      jwtToken: '',
      userIsLogin: false,
      loginError: action.err,
      authLoading: false
    }),
    UPDATE_STATE: () =>
      // delete action.type;
      ({
        ...state,
        ...action
      })
  };
  if (!handlers[action.type]) {
    return state;
  }
  return handlers[action.type]();
};

export default authReducer;
