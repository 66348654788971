import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Input as LoginInput } from "../../../components/Common";

const PasswordValidation = ({
  errors,
  touched,
  setFieldValue,
  setPasswordVal,
  setConfirmPasswordVal,
  passwordValidation,
}) => (
  <>
    <LoginInput
      class="nd-big-inp lng w100"
      required
      noAutocomplete
      label="Enter new password"
      type="password"
      onChange={(value) => {
        setFieldValue("password", value);
        setPasswordVal(value);
      }}
      error={touched.password && errors.password}
    />
    <LoginInput
      class="nd-big-inp lng w100"
      required
      noAutocomplete
      label="Confirm password"
      type="password"
      error={errors.confirmPassword}
      onChange={(value) => {
        setFieldValue("confirmPassword", value);
        setConfirmPasswordVal(value);
      }}
    />
    <div className="validation-list">
      <div className={`${passwordValidation(0) && "err"}`}>
        <FontAwesomeIcon icon="check" />
        <FontAwesomeIcon icon="times" />
        &nbsp;Must be 10 characters or more
      </div>
      <div className={`${passwordValidation(1) && "err"}`}>
        <FontAwesomeIcon icon="check" />
        <FontAwesomeIcon icon="times" />
        &nbsp;Includes at least 1 upper case alphabet
      </div>
      <div className={`${passwordValidation(2) && "err"}`}>
        <FontAwesomeIcon icon="check" />
        <FontAwesomeIcon icon="times" />
        &nbsp;Includes at least 1 lower case alphabet
      </div>
      <div className={`${passwordValidation(3) && "err"}`}>
        <FontAwesomeIcon icon="check" />
        <FontAwesomeIcon icon="times" />
        &nbsp;Password should contains numbers
      </div>
      <div className={`${passwordValidation(4) && "err"}`}>
        <FontAwesomeIcon icon="check" />
        <FontAwesomeIcon icon="times" />
        &nbsp;Include special symbols like @ # $ etc
      </div>
      <div className={`${passwordValidation(5) && "err"}`}>
        <FontAwesomeIcon icon="check" />
        <FontAwesomeIcon icon="times" />
        &nbsp;Confirmation should be the same
      </div>
    </div>
  </>
);

export default PasswordValidation;

PasswordValidation.propTypes = {
  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldValue: PropTypes.func,
  setPasswordVal: PropTypes.func,
  setConfirmPasswordVal: PropTypes.func,
  passwordValidation: PropTypes.func,
};
