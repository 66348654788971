export const COUNTRY_NAME_CODE = [
  {
    name: "United States",
    dial_code: "+1",
    code: "US",
  },
  {
    name: "Canada",
    dial_code: "+1",
    code: "CA",
  }
 
];
