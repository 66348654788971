import React, { useCallback, useState } from "react";
import PerspectiveViewerComponent from "./PerspectiveViewer";
import { MARKET_DATA } from "../../constant";
import { BACKEND_API } from "../../config";

const MarketDataViewer = () => {
  const [loading, setLoading] = useState(true);

  // Memoize requestBodyBuilder to avoid unnecessary re-creations
  const requestBodyBuilder = useCallback(
    (startTimeUTC, stopTimeUTC) => ({
      startTime: startTimeUTC,
      stopTime: stopTimeUTC,
      measurements: ["DAM_SETTLEMENT_POINT_PRICES", "RTM_LMP"],
      pointNames: ["HB_WEST", "SGSA_DGR", "HB_HUBAVG"],
    }),
    []
  );

  // Memoize configBuilder to avoid unnecessary re-creations
  const configBuilder = useCallback(
    (perspectiveTheme) => ({
      title: "ERCOT",
      plugin: "Datagrid",
      group_by: ["Time"],
      split_by: ["Location", "Measurement"],
      columns: ["Value"],
      filter: [],
      sort: [["Time", "desc"]],
      settings: false,
      aggregates: {
        Location: "any",
        Value: "last by index",
      },
      theme: perspectiveTheme,
    }),
    []
  );

  return (
    <PerspectiveViewerComponent
      title={MARKET_DATA}
      endpoint={`${BACKEND_API}fractal-energy/ERCOT`}
      requestBodyBuilder={requestBodyBuilder}
      configBuilder={configBuilder}
      loading={loading}
      setLoading={setLoading}
    />
  );
};

export default MarketDataViewer;
