import React from 'react';
import { Provider } from 'react-redux';

import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


import { ContextProvider } from './components/Common/Theme/Context';

import AuthProvider from './auth/Context/AuthContext';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import store from './store';


function noop() {}

if (process.env.NODE_ENV === 'master') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

window.globalEmit = {};
window.globalEvents = {
  userLoaded: new Promise((r) => (window.globalEmit.fireUserLoaded = r))
};



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <Tooltip place='bottom-start' id="tooltip" />
        <ToastContainer />
        <ContextProvider>
             <App />
        </ContextProvider>
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();