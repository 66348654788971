import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../constant/routes";
import { MarketDataViewer, EMSDataViewer } from "../../components/Home";
import { ReportManager, ReportUpload } from "../../components/Reports";
import Analyst from "../../components/Analyst";
import { PageNotFound,AccessNotFound } from "../../components/Common";
import { storeConnector } from "../../store";
import { USER_ACCESS_LEVEL } from "../../constant/User";

const ACCESS_PERMISSIONS = {
  [USER_ACCESS_LEVEL.VIEWER]: [PRIVATE_ROUTES.HOME, PRIVATE_ROUTES.REPORT],
  [USER_ACCESS_LEVEL.ANALYST]: [
    PRIVATE_ROUTES.HOME,
    PRIVATE_ROUTES.REPORT,
    PRIVATE_ROUTES.REPORT_UPLOAD,
  ],
  [USER_ACCESS_LEVEL.TRADER]: Object.values(PRIVATE_ROUTES), // Access all routes
};
const NavigationContainer = ({
  actions,
  group,
}) => {

  const allowedRoutes = ACCESS_PERMISSIONS[group] || [];
  const checkAccessLevel = (path) => {
    return allowedRoutes.includes(path)
  }

  return (
    <Switch>
     
        <Route
          path={PRIVATE_ROUTES.HOME}
          exact
          component={() => {
            return (
              checkAccessLevel(PRIVATE_ROUTES.HOME) ?  
              <div className="chart-grid">
              <EMSDataViewer />
              <MarketDataViewer />
            </div> : <AccessNotFound />
            )
          }
        }
        />
     
        <Route
          exact
          path={PRIVATE_ROUTES.REPORT}
          component={() => {
            return (
              !checkAccessLevel(PRIVATE_ROUTES.REPORT) ? <AccessNotFound /> : <ReportManager /> 
            )
          }
        }

        />
     
      
        <Route
          exact
          path={PRIVATE_ROUTES.REPORT_UPLOAD}
          component={() => {
              return (
                checkAccessLevel(PRIVATE_ROUTES.REPORT_UPLOAD) ? <ReportUpload /> : <AccessNotFound />
              )
            }
          }
        />
  
   
        <Route
          exact
          path={PRIVATE_ROUTES.ANALYST}
          component={() => {
            return (
              checkAccessLevel(PRIVATE_ROUTES.ANALYST) ? <Analyst /> : <AccessNotFound />
            )
          }
           
          }
        />
   
      <Route
        exact
        path={PRIVATE_ROUTES.PAGENOTFOUND}
        component={() => <PageNotFound />}
      />
     
      {/* Redirect to PageNotFound if the user tries to access a restricted route */}
      {/* <Redirect to={PRIVATE_ROUTES.HOME} /> */}
    </Switch>
  );
};

NavigationContainer.propTypes = {
  actions: PropTypes.object,
  history: PropTypes.object,
  accountname: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  email: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  group: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default storeConnector(NavigationContainer, {
  service: ["actions", "history"],
  auth: ["accountname", "email", "group"],
});
