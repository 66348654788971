import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  Autocomplete,
  CircularProgress,
  Button,
  TextField,
  IconButton,
  DialogTitle,
  DialogContent,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { EditIcon } from '../../../assets/svg';
import SaveIcon from "@mui/icons-material/Save";
import { storeConnector } from "../../../store";
import { SAVE, USER } from "../../../constant/User";
import { COUNTRY_NAME_CODE } from "../../../constant/Country";
import { Style } from "../../../components/Modal/ModalStyles";
import { Constraints, SERVICE_STATUS } from "../../../constant/Analyst";

const Notification = ({
  setOpenModal,
  openModal,
  usersData,
  username,
  actions,
  userId,
  setIsEditing,
  isEditing,
  handelPhoneVerify,
}) => {

  const modalRef = useRef();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [selectedCountry, setSelectedCountry] = useState({
    label: "United States",
    value: "+1",
  });

  const [emailAlert, setEmailAlert] = useState(SERVICE_STATUS[1]);
  const [textAlert, setTextAlert] = useState(SERVICE_STATUS[1]);
  const [emailError, setEmailError] = useState(false);
  const [smsError, setSmsError] = useState(false);
  const [error, setError] = useState(false);

  const toggleEditMode = () => {
    setIsEditing(false);
    handelPhoneVerify();
    handleClose();
  };

  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const CountryData = useMemo(() => {
    const data = COUNTRY_NAME_CODE?.map((country) => {
      return {
        label: country.name,
        value: country.dial_code,
      };
    });
    return data;
  }, []);

  /**
   * @desc Sets the selected country based on the provided country code.
   * @param {string} countryCode - The country code to match and set the selected country.
   */

  const setCountryByCode = useCallback(
    (countryCode, code) => {
      const country = CountryData?.find(
        (country) => country.value === countryCode && code === country.label
      );
      if (country) {
        setSelectedCountry(country);
      } else {
        setSelectedCountry({
          label: "United States",
          value: "+1",
        });
      }
    },
    [CountryData]
  );

  // Extract the logic into an independent function to avoid nested ternaries
  const getServiceStatus = (alertValue) => {
    if (alertValue === Constraints.ACTIVE) {
      return SERVICE_STATUS[0];
    } else if (alertValue === Constraints.PAUSED) {
      return SERVICE_STATUS[1];
    }
    return SERVICE_STATUS[1]; // Default value when neither condition matches
  };
  const getAlertStatus = (alertValue) => {
    if (alertValue === SERVICE_STATUS[0]) {
      return Constraints.ACTIVE;
    } else if (alertValue === SERVICE_STATUS[1]) {
      return Constraints.PAUSED;
    }
    return SERVICE_STATUS[1]; // Default value
  };

  useEffect(() => {
    actions.user
      .getUserDetails({ userid: userId })
      .then((res) => {
        if (res.status === 200 && res.data.data.length > 0) {
          setCountryByCode(
            res?.data?.data[0]?.countryCode,
            res?.data?.data[0].country
          );
          setPhone(res?.data?.data[0]?.decipheredNumber);
          setEmailAlert(getServiceStatus(res?.data?.data[0]?.emailAlert));
          setTextAlert(getServiceStatus(res?.data?.data[0]?.textAlert));
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  }, [actions, setCountryByCode, userId]);

  const handleOutsideClick = useCallback(
    (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    },
    [modalRef, handleClose]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const handleCountryChange = (event, value) => {
    setSelectedCountry(value);
  };
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    const isValidInput = /^\d*$/.test(value);
    if (isValidInput) {
      setPhone(value);
    }
  };

  //get msg credit request.
  const getCreditCounts = () => {
    actions.user
      .getUserDetails({ userid: userId })
      .then((res) => {
        console.log();
      })
      .catch((err) => {
        console.log("get login user details", err);
      });
  };

  const getAlerts = useCallback(() => {
    actions.alert
      .getAlerts()
      .then((res) => {})
      .catch((err) => {
        console.log("getAlerts err: ", err);
      });
  }, [actions.alert]);

  /**
   * @desc update phone number and user's credit.
   */
  function handelUpdate() {
    setLoading(true);
    if (!!selectedCountry && !!phone && !!emailAlert && !!textAlert) {
      const emailAlertStatus = getAlertStatus(emailAlert);
      const textAlertStatus = getAlertStatus(textAlert);
      getAlerts();

      actions.user
        .updateCreditAdmin({
          userid: userId,
          emailAlert: emailAlertStatus,
          textAlert: textAlertStatus,
        })
        .then((res) => {
          if (res?.data.code === 200) {
            actions.service.notify("Service status has been modified");
            getAlerts();
          }
        })
        .catch((err) => {
          actions.service.notify(Constraints.alertReadUnsuccess);
          console.log("occure service err: ", err);
        });

      getCreditCounts();

      setTimeout(() => {
        setLoading(false);
      }, 2000);
      setOpenModal(false);
    } else {
      if (!selectedCountry || !phone) {
        setError("Please enter a valid phone number with the country code.");
      }
      if (!emailAlert) {
        setEmailError("Please select at least one email service");
      }
      if (!textAlert) {
        setSmsError("Please select at least one SMS service");
      }

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }

  return (
    <Box
      style={{
        display: openModal ? "block" : "none",
      }}
      sx={Style.chart.modelBox}
    >
      <Grid
        ref={modalRef}
        container
        justifyContent="center"
        alignItems="center"
        style={{
          display: openModal ? "block" : "none",
        }}
        sx={[
          Style.chart.modelGridOTP,
          { background: "var(--background-color)" },
        ]}
      >
        <DialogTitle id="alert-dialog-title" sx={Style.chart.dialogTitle}>
          {USER.SettingAlert}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={Style.chart.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box id="alert-dialog-description">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    margin: "20px 0px 10px 0px",
                  }}
                >
                  {!phone ? (
                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "50px",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress style={{ color: "var(--text-color)" }} />
                    </Grid>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Autocomplete
                          disablePortal
                          options={CountryData}
                          value={selectedCountry || null}
                          onChange={handleCountryChange}
                          fullWidth
                          renderInput={(params) => (
                            <TextField {...params} label={"Country"} />
                          )}
                          disabled={!isEditing}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          id="number"
                          name="number"
                          label="Phone number"
                          variant="outlined"
                          sx={Style.createAlert.textFieldStyle}
                          InputProps={{
                            startAdornment: selectedCountry ? (
                              <InputAdornment position="start">
                                {selectedCountry.value}
                              </InputAdornment>
                            ) : null,
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  onClick={toggleEditMode}
                                  sx={{
                                    display: "contents",
                                    justifyContent: "end",
                                    padding: 0,
                                  }}
                                >
                                  {isEditing ? "Save" : <EditIcon />}
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                          onChange={handlePhoneChange}
                          value={selectedCountry ? phone : ""}
                          disabled={!isEditing}
                        />
                      </Grid>
                      {error && (
                        <Grid Grid item xs={12} style={Style.chart.errorMsg}>
                          <div style={{ color: "red" }}>{error}</div>
                        </Grid>
                      )}

                      <Grid item xs={6}>
                        <Autocomplete
                          disablePortal
                          options={SERVICE_STATUS}
                          value={emailAlert}
                          onChange={(e, value) => setEmailAlert(value)}
                          fullWidth
                          renderInput={(params) => (
                            <TextField {...params} label="Email Service" />
                          )}
                        />
                        {emailError && (
                          <Grid Grid item xs={12} style={Style.chart.errorMsg}>
                            <div style={{ color: "red" }}>{emailError}</div>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                          disablePortal
                          options={SERVICE_STATUS}
                          value={textAlert}
                          onChange={(e, value) => {
                            setTextAlert(value);
                          }}
                          fullWidth
                          renderInput={(params) => (
                            <TextField {...params} label="SMS Service" />
                          )}
                        />
                        {smsError && (
                          <Grid Grid item xs={12} style={Style.chart.errorMsg}>
                            <div style={{ color: "red" }}>{smsError}</div>
                          </Grid>
                        )}
                      </Grid>

                      <Grid item xs={12} style={Style.chart.settingButtonGrid}>
                        <Button
                          onClick={() => {
                            handelUpdate();
                          }}
                          disabled={loading}
                          autoFocus
                          sx={Style.chart.settingSaveButton}
                        >
                          {SAVE} &nbsp;
                          {loading ? (
                            <CircularProgress
                              style={Style.chart.circularProgress}
                            />
                          ) : (
                            <SaveIcon />
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Grid>
    </Box>
  );
};

Notification.propTypes = {
  setOpenModal: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
  usersData: PropTypes.array,
  username: PropTypes.string,
  actions: PropTypes.object.isRequired,
  userId: PropTypes.string,
  user_email: PropTypes.string,
};

export default storeConnector(Notification, {
  service: "all",
  auth: ["userId"],
});
