import { makeStyles } from "@mui/styles";
export const Style = {
  alertTable: {
    box: {
      display: "flex",
      minWidth: "700px",
      overflowX: "auto",
    },
    innerBox: {
      transition: "width 0.3s",
    },
    appBar: {
      backgroundColor: "var(--background-color)",
      height:42,
      boxShadow: "0 0 10px var(--chart-shadow-color)",
    },
    toolbar: {
      minHeight: "min-content !important",
      height: "40px",
      padding: "0px !important",
      justifyContent: "end",
    },
    flexWidth: {
      display: "flex",
      width: "auto",
      justifyContent: "end",
    },
    TableContainer: {
      height: "100%",
      width: "100%",
    },
    backgroundWhite: {
      backgroundColor: "white",
    },
    mainToolbar: {
      minHeight: "min-content !important",
      height: "40px",
      padding: "0px !important",
      boxShadow: "0 0 10px var(--chart-shadow-color)"
    },
    paddingStack: {
      padding: "10px",
      position: "relative",
      borderTop: "1px solid var(--chart-border-color)",
    },
    mainBox: {
      flexWrap: "wrap",
      rowGap: "10px",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
    },
    headTitle: {
      fontWeight: "bold",
      fontSize: "15px",
    },
    headSideBar: {
      display: "flex",
      alignItems: "center",
    },
    smsCredit: {
      marginRight: "10px",
      color: "#000000",
      fontWeight: "bold",
    },
    settingIcon: {
      marginRight: "10px",
      display: "flex",
      alignItems: "center",
    },
    settingAddIcon: {
      fontSize: "24px",
    },
    smsChipColor: {
      color: "var(--text-color)",
      fontWeight: "bold",
      fontSize: "15px",
    },
    smsChipColorRed: {
      color: "red",
      fontWeight: "bold",
      fontSize: "14px",
    },
    tableCell: {
      minWidth: "100px",
      fontWeight: "bold",
    },
    tableCellBody: {
      fontWeight: "bold",
      padding: "2px 16px",
      fontSize: "15px",
    },
    tableCellBodyIsRead: {
      fontWeight: "normal",
      backgroundColor: "white",
      padding: "2px 16px",
    },
    noDataTitle: {
      margin: "6px",
      fontWeight: "bold",
      alignItems: "center",
    },
    footerBox: {
      margin: "16px 0",
      width: "100%",
      display: "flex",
      justifyContent: "end",
    },
    paginationBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    selectRowsBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "240px",
      color: "#707070",
    },
    selectOption: {
      padding: "6px",
      borderRadius: "10px",
      color: "#707070",
      cursor: "pointer",
    },
  },
  chart: {
    modelBox: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)",
      zIndex: 10,
    },
    modelGrid: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: "#fff",
      borderRadius: "5px",
      zIndex: 1,
      width: "70%",
      maxWidth: "610px",
      boxShadow: "rgba(0, 0, 0, 0.5) 0px 5px 15px",
      "@media (max-width: 768px)": {
        width: "90%",
      },
    },
    modelGridOTP: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: "#fff",
      borderRadius: "5px",
      zIndex: 1,
      width: "70%",
      maxWidth: "610px",
      maxHeight: "85vh",
      overflowY: "auto",
      boxShadow: "rgba(0, 0, 0, 0.5) 0px 5px 15px",
      "@media (max-width: 768px)": {
        width: "90%",
      },
    },
  },
  createAlert: {
    textFieldStyle: {
      width: "100%",
    },
  },
};

export const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTabs-root": {
      backgroundColor: "var(--peri-color)",

      "& .MuiButtonBase-root": {
        "&.MuiTab-root": {
          fontSize: "14px",
          textTransform: "capitalize",
          lineHeight: "normal",
        },
      },
      "& .Mui-selected": {
        color: "#000000",
        fontWeight: "600",
      },
      "& .MuiTabs-indicator": {
        backgroundColor: "#000000",
      },
      "& .MuiButtonBase-root.MuiTab-root": {
        color: "#000000",
      },
    },
    "& .header-db": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& h4": {
        margin: "0 0",
      },
    },

    "& .MuiButtonBase-root.MuiToggleButton-root": {
      fontSize: "15px",
    },

    "& .Box-wrapper ": {
      maxWidth: "200px",
      background: "white",
      textAlign: "center",
    },
  },
}));

export const useTableStyles = makeStyles(() => ({
  TableContainer: {
    "& .MuiTableContainer-root": {},
    "& .table": {
      width: "100%",
      height: "100%",
      margin: "0px 0px",
      border: "1px solid var(--chart-border-color)",
      "& .tableHeader": {
        "& .MuiTableCell-head": {
          padding: "5px 16px",
        },
        cell: {
          display: "flex",
          alignItems: "center",
        },
        "& div": {
          display: "flex",
          "& p": {
            fontSize: "16px",
          },
          "&:hover .title": {
            display: "none",
          },
          "& .iconsWrapper": {
            display: "none",
          },
          "&:hover .iconsWrapper": {
            display: " flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            marginLeft: 5,
            "& p": {
              fontSize: "16px",
            },
          },
        },
      },
      "& .tableBody": {
        overflowY: "auto",
      },
    },
  },
}));

export const useMenuStyles = makeStyles(() => ({
  options: {
    display: "none",
    position: "absolute",
    zIndex: 11,
    backgroundColor: `rgb(255, 255, 255)`,
    minWidth: 160,
    boxShadow: `rgba(0, 0, 0, 0.1) -8px 0px 10px 1px,
                rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
                rgba(0, 0, 0, 0.14) 8px 0px 10px 1px,
                rgba(0, 0, 0, 0.12) 0px -5px 14px 2px`,
    borderRadius: 4,
    right: 10,
    top: 40,
  },
  optionItem: {
    padding: "8px 15px",
    display: "block",
    textDecoration: "none",
    color: "black",
    textAlign: "left",
    width: "100%",
    background: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ddd",
      color: "var(--black-color)"
    },
  },
}));

export const IconButtonStyle = {
  alertTable: {
    flexBox: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    paddingStack: {
      padding: "10px",
      position: "relative",
    },
    descriptionBox: {
      padding: "6px 0px",
    },
    descriptionText: {
      fontSize: "12px",
      paddingTop: "4px",
      color: "#8d8d8d",
    },
    descriptionTagBox: {
      display: "flex",
      justifyContent: "start",
      flexWrap: "wrap",
      gap: "12px",
    },
    descriptionTagTrue: {
      fontSize: "12px",
      display: "flex",
      justifyContent: "center",
      padding: "3px 6px",
      borderRadius: "10px",
      background: "#4AA96C",
      color: "white",
    },
    descriptionTagFalse: {
      fontSize: "12px",
      display: "flex",
      justifyContent: "center",
      padding: "3px 6px",
      borderRadius: "10px",
      background: "#F55C47",
      color: "white",
    },
    descriptionTagActive: {
      fontSize: "12px",
      display: "flex",
      justifyContent: "center",
      padding: "3px 6px",
      borderRadius: "10px",
      background: "#4AA96C",
      color: "white",
      textTransform: "capitalize",
      alignItems: "center",
    },
    descriptionTagPaused: {
      fontSize: "12px",
      display: "flex",
      justifyContent: "center",
      padding: "3px 6px",
      borderRadius: "10px",
      background: "#8B8588",
      color: "white",
      textTransform: "capitalize",
      alignItems: "center",
    },
    mainBox: {
      flexWrap: "wrap",
      rowGap: "10px",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
    },
    headTitle: {
      fontWeight: "bold",
      fontSize: "16px",
    },
    sideMenuIcon: {
      width: "auto",
      margin: "0px !important",
      padding: "0px",
    },
    sideMainBox: {
      position: "relative",
      width: "100%",
      padding: "0px 6px",
      marginBottom: "6px",
      borderLeft: "4px solid var(--peri-color)",
      borderRadius: "6px",
      boxShadow: `rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px`,
    },
    sideMenuBox: {
      width: "100%",
      height: "40px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    sideMenuBoxText: {
      paddingLeft: "5px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "140px",
      fontSize: "15px",
      fontWeight: "bold",
    },
    iconBoxFlex: {
      display: "flex",
      height: "40px",
      justifyContent: "center",
      alignItems: "center",
      columnGap: "8px",
    },
  },
};
