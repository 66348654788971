import { dispatcher } from "../index";
import {
  getAlerts,
  addAlert,
  updateAlert,
  deleteAlert,
  readSelectedAlerts,
  sendPhoneOTP,
  verifyPhoneOTP,
  getGridLocationsByStream,
  getStreamNames,
  readAllAlerts,
} from "../../services";

const alertActions = {
  getAlerts() {
    return async function (dispatch) {
      return new Promise(async (resolve, reject) => {
        await getAlerts()
          .then((response) => {
            if (response.code === 200) {
              dispatch(
                dispatcher("GET_ALERTS", {
                  payload: response.data,
                })
              );
            }

            setTimeout(() => {
              dispatch(
                dispatcher("SET_ALERT_LOADING", {
                  payload: false,
                })
              );
            }, 1000);

            resolve(response.data);
          })
          .catch((error) => {
            resolve([]);
          });
      });
    };
  },
  addAlert(data) {
    return async function (dispatch) {
      return new Promise(async (resolve, reject) => {
        await addAlert(data)
          .then((response) => {
            dispatch(
              dispatcher("SET_ALERT_NAME", {
                chartName: data,
              })
            );
            resolve(response);
          })
          .catch((error) => {
            resolve([]);
          });
      });
    };
  },
  updateAlert(data) {
    return async function (dispatch) {
      return new Promise(async (resolve, reject) => {
        await updateAlert(data)
          .then((response) => {
            dispatch(
              dispatcher("EDIT_ALERT", {
                editChart: data,
              })
            );
            resolve(response);
          })
          .catch((error) => {
            resolve([]);
          });
      });
    };
  },
  deleteAlert(data) {
    return async function (dispatch) {
      return new Promise(async (resolve, reject) => {
        await deleteAlert(data)
          .then((response) => {
            dispatch(
              dispatcher("DELETE_ALERT", {
                chartName: data,
              })
            );
            resolve(response);
          })
          .catch((error) => {
            resolve([]);
          });
      });
    };
  },
  readAllAlerts() {
    return async function (dispatch) {
      return new Promise(async (resolve, reject) => {
        const dashboardLoading = (status) => {
          dispatch(
            dispatcher("SET_DASHBOARD_LOADING", {
              payload: status,
            })
          );
        };

        dashboardLoading(true);

        await readAllAlerts()
          .then((response) => {
            dashboardLoading(false);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };
  },

  readSelectedAlerts(data) {
    return async function (dispatch) {
      return new Promise(async (resolve, reject) => {
        const dashboardLoading = (status) => {
          dispatch(
            dispatcher("SET_DASHBOARD_LOADING", {
              payload: status,
            })
          );
        };

        dashboardLoading(true);

        await readSelectedAlerts(data)
          .then((response) => {
            dashboardLoading(false);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };
  },

  sendPhoneOTP(data) {
    return async function (dispatch) {
      return new Promise(async (resolve, reject) => {
        await sendPhoneOTP(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            resolve(error);
          });
      });
    };
  },
  verifyPhoneOTP(data) {
    return async function (dispatch) {
      return new Promise(async (resolve, reject) => {
        await verifyPhoneOTP(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            resolve(error);
          });
      });
    };
  },

  getGridLocations(data) {
    return async function (dispatch) {
      return new Promise(async (resolve, reject) => {
        await getGridLocationsByStream(data?.stream)
          .then((response) => {
            dispatch(
              dispatcher("GET_GRID_LOCATION", {
                payload: response?.data?.data[data?.stream],
              })
            );
            resolve(response);
          })
          .catch((error) => {
            resolve([]);
          });
      });
    };
  },
  getStreamNames() {
    return async function (dispatch) {
      return new Promise(async (resolve, reject) => {
        await getStreamNames()
          .then((response) => {
            if (response.data) {
              dispatch(
                dispatcher("GET_STREAM_NAMES", {
                  payload: response.data.data,
                })
              );
            }
            resolve(response);
          })
          .catch((error) => {
            resolve([]);
          });
      });
    };
  },
  // notification list
  setAlertData(data) {
    return async function (dispatch) {
      return new Promise(async (resolve, reject) => {
        dispatch(
          dispatcher('SET_ALERT_PRICES_ROOM', {
            payload: data
          })
        );
        resolve(true);
      });
    };
  },
  setAlertDataAppend(data) {
    return async function (dispatch) {
      return new Promise(async (resolve, reject) => {
        dispatch(
          dispatcher('SET_APPEND_ALERT', {
            payload: data
          })
        );
        resolve(true);
      });
    };
  },
  setAlertUnreadCount(data) {
    return async function (dispatch) {
      return new Promise(async (resolve, reject) => {
        dispatch(
          dispatcher('SET_ALERT_UNREAD_COUNT', {
            payload: data
          })
        );
        resolve(true);
      });
    };
  },
};

export default alertActions;
