export const Style = {
  confirmSignUp: {
    margin: {
      margin: "40px 0px",
    },
    marginTop: {
      marginTop: "70px",
    },
    flex: {
      display: "flex",
      justifyContent: "center",
    },
    relative: {
      position: "relative",
    },
    alignMargin: {
      alignSelf: "center",
      margin: "15px 0",
    },
  },
  NotApproved: {
    minWidth: {
      minWidth: "unset",
    },
  },
  forgotPassword: {
    button: {
      position: "relative",
      marginTop: "30px",
      bottom: "unset",
    },
    buttonText: {
      textDecoretion: "underline",
    },
    marginTop: {
      marginTop: "70px",
    },
    margin: {
      margin: "20px 0px",
    },
  },
  signupNew: {
    descriptionSignupReq: {
      fontSize: "20px",
      color: "#fff",
    },
    wrap: {
      flexWrap: "wrap",
    },
  },
};
