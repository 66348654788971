const appendAlertPrice = (alert_prices, response) => {
  console.log("appendAlertPrice alert_prices: ", alert_prices);
  if (response?.data === false) return alert_prices;
  if (response?.data?.length === 0) return alert_prices;

  if (alert_prices.length > 0) {
    const isExists = alert_prices.find((item) => {
      return item.id === response[0].id;
    });

    if (isExists) {
      return alert_prices;
    } else {
      return [...response, ...alert_prices];
    }
  } else {
    return [...response, ...alert_prices];
  }
};

const alertReducers = (
  state = {
    alertList: [],
    alertLoading: true,
    editChart: {},
    deleteAlert: {},
    dashboardLoading: true,
    gridLocations: [],
    streams: [],
    alert_prices: [],
    alertCount: 0
  },
  action
) => {
  const handlers = {
    GET_ALERTS: () => ({
      ...state,
      alertList: action.payload,
    }),
    SET_ALERT_LOADING: () => ({
      ...state,
      alertLoading: action.payload,
    }),
    EDIT_ALERT: () => ({
      ...state,
      editAlert: action.payload,
    }),
    DELETE_ALERT: () => ({
      ...state,
      deleteAlert: action.payload,
    }),
    SET_DASHBOARD_LOADING: () => ({
      ...state,
      dashboardLoading: action.payload,
    }),
    GET_GRID_LOCATION: () => ({
      ...state,
      gridLocations: action.payload,
    }),
    GET_STREAM_NAMES: () => ({
      ...state,
      streams: action.payload || [],
    }),
    SET_ALERT_PRICES_ROOM: () => ({
      ...state,
      alert_prices: action.payload,
    }),
    SET_APPEND_ALERT: () => {
      return {
        ...state,
        alert_prices: appendAlertPrice(state.alert_prices, action.payload),
      };
    },
    SET_ALERT_UNREAD_COUNT: () => ({
      ...state,
      alertCount: action.payload
    }),
  };
  if (!handlers[action.type]) {
    return state;
  }
  return handlers[action.type]();
};

export default alertReducers;
