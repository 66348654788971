import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "../Common/Theme/Context";
import { AlertDialog } from "../Common";
import { storeConnector } from "../../store";
import { AlertConstants, Constraints } from "../../constant/Analyst";
import { AlertTable, CreateAlert, EditAlert } from "./Alert";
import AddPhone from "./AddPhone";
import socket from "../../services/socket";
import "./Analyst.css";

const Index = ({ actions, userId, username }) => {
  const { theme } = useContext(ThemeContext);

  const [open, setOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(true);

  const [phoneVerify, setPhoneVerify] = useState(false);
  const [isMarkAll, setIsMarkAll] = useState(false);
  const [isMarkSelected, setIsMarkSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const busList = [];

  const addChart = () => {
    setOpen(true);
  };

  const handelPhoneVerify = () => {
    setOpen(false);
    setPhoneVerify(1);
  };

  /**
   * @desc Deletes a chart from the Dashboard.
   * @desc Updates the chart and notifies the user if there are changes.
   * @param {value}
   */
  const onDeleteAlert = async (value) => {
    setIsSaving(true);
    try {
      await actions.alert
        .deleteAlert({
          id: value?.id,
        })
        .then((res) => {
          if (res.code === 200) {
            getAlerts();
            refreshAlerts();
            setIsDelete(false);
            setIsSaving(false);
          }
        });
    } catch (err) {
      setIsDelete(false);
      setIsSaving(false);
      console.log("Delete Alert error", err);
    }
  };

  const refreshAlerts = useCallback(() => {
    setTimeout(() => {
      socket.emit("JOIN_ALERT_ROOM", {
        userId: userId,
      });
    }, 1000);
  }, [userId]);

  const getAlerts = useCallback(() => {
    actions.alert
      .getAlerts()
      .then((res) => {
        refreshAlerts();
      })
      .catch((err) => {
        console.log("getAlerts err: ", err);
      });
  }, [actions.alert, refreshAlerts]);

  /**
   * @desc  socket emit event JOIN_ALERT_ROOM for get alerts
   */
  useEffect(() => {
    getAlerts();

    console.log("Connect socket room", userId);    
    // Display notification on list
    socket.on("ALERT_ROOM_CONNECTED", async (res) => {
      if (res?.code === 200) {
        actions.alert.setAlertData(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });

    // Display unread notification count
    socket.on("ALERT_UNREAD_COUNTS", async (res) => {
      if (res?.code === 200) {
        actions.alert.setAlertUnreadCount(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });

    socket.on("ALERT_ROOM_CONNECT_APPEND", async (res) => {
      console.log("ALERT_ROOM_CONNECT_APPEND res: ", res);
      if (res?.code === 200) {
        socket.emit('ALERT_GET_UNREAD_COUNT', {
          userid: userId
        })
        
        actions.alert.setAlertDataAppend(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });

    socket.on("GET_TEXT_CREDITS", async (res) => {
      if (res?.data) {
        
        actions.user
          .setCreditCountDetails(res?.data)
          .then((res) => {
            getAlerts();
          })
          .catch((err) => {
            console.log("set credit count", err);
          });
      }

    });

    return () => {
      socket.emit("LEAVE_ALERT_ROOM", {
        userId: userId,
      });
    };
  }, [userId, actions.alert, getAlerts, actions.user]);

  /**
   * @desc real all alerts from the Dashboard.
   * @desc Updates the chart and notifies the user if there are changes.
   */
  const onReadAllAlert = useCallback(async () => {
    setIsSaving(true);

    try {
      await actions.alert
        .readAllAlerts()
        .then((res) => {
          if (res.code === 200) {
            actions.service.notify(Constraints.alertReadSuccess);
            setCurrentPage(1);
            refreshAlerts();
          }
        })
        .catch((err) => {
          console.log("read notification alert error: ", err);
          actions.service.notify(Constraints.alertReadUnsuccess);
        })
        .finally(() => {
          setIsMarkAll(false);
          setIsSaving(false);
        });
    } catch (err) {
      setIsMarkAll(false);
      setIsSaving(false);
      console.log("catch read notification alerts error", err);
    }
  }, [actions.alert, actions.service, refreshAlerts]);

  /**
   * @desc real selected alerts from the Dashboard.
   * @desc Updates the chart and notifies the user if there are changes.
   */
  const onReadSelectAlert = async (id) => {
    setIsSaving(true);
    try {
      await actions.alert
        .readSelectedAlerts({ notificationIds: id })
        .then((res) => {
          if (res.code === 200) {
            actions.service.notify(Constraints.alertSelectReadSuccess);
            refreshAlerts();
            setCurrentPage(1);
          }
        })
        .catch((err) => {
          console.log("read select notification alert error: ", err);
          actions.service.notify(Constraints.alertReadUnsuccess);
        })
        .finally(() => {
          setIsMarkSelected(false);
          setSelectedItems([]);
          setIsSaving(false);
        });
    } catch (err) {
      setIsMarkSelected(false);
      setSelectedItems([]);
      setIsSaving(false);
      console.log("read selected alerts error", err);
    }
  };

  /**
   * @desc Create a alert.
   * @desc Create the alert and notifies the user if there are changes.
   * @param {values}
   */
  const onCreateAlert = async (values) => {
    try {
      await actions.alert
        .addAlert({
          gridName: values.gridName,
          alertTitle: values.name,
          condition: values.condition,
          alertPrice: values.price,
          streamName: values.streamName,
          email: values.email,
          sms: values.sms,
          emailId: values.emailId,
          country: values.country,
          phoneNumber: values.phoneNumber,
        })
        .then((result) => {
         
          getAlerts();
          refreshAlerts();
          setOpen(false);
        });
    } catch (err) {
      console.error("An error occured when creating alerts", err);
      refreshAlerts();
    }
  };

  /**
   * @desc  Edit a Alert.
   * @desc Updates the alert and notifies the user if there are changes.
   * @param {values}
   */
  const onEditAlert = async (values) => {
    const obj = {
      gridName: values.gridName,
      alertTitle: values.name,
      condition: values.condition,
      alertPrice: values.price,
      streamName: values.streamName,
      email: values.email,
      sms: values.sms,
      textAlert: values.textAlert,
      emailAlert: values.emailAlert,
      userid: userId,
      id: values.id,
    };

    try {
      const res = await actions.alert.updateAlert(obj);
      if (res) {
        setTimeout(() => {
          if (res?.data?.length > 0) {
            actions.service.notify(res.data);
          }
        }, 1000);
        getAlerts();
        refreshAlerts();
        getCreditCounts();
        setIsEdit(false);
      }
    } catch (err) {
      console.log("Update alert error", err);
    }
  };

  const getCreditCounts = useCallback(() => {
    actions.user
      .getUserDetails({ userid: userId })
      .then((res) => {
      })
      .catch((err) => {
        console.log("get login user details", err);
      });
  }, [actions.user, userId]);

  const getAllStreamNames = useCallback(() => {
    actions.alert
      .getStreamNames()
      .then((res) => {})
      .catch((err) => {setLoading(false)});
  }, [actions.alert]);

  useEffect(() => {
    getAlerts();
    getCreditCounts();
    getAllStreamNames();
  }, [getAlerts, getCreditCounts, getAllStreamNames]);

  return (
    <div
      className={`alert-table-container ${theme}-theme`}
      style={{
        height: `calc(100vh - 100px)`,
        backgroundColor:
          theme === "light" ? "var(--unicorn-silver-color)" : "unset",
        overflow: "auto",
        padding: "10px 16px 16px",
      }}
    >
      <AlertTable
        setIsEdit={setIsEdit}
        setIsDelete={setIsDelete}
        addAlert={addChart}
        handelPhoneVerify={handelPhoneVerify}
        setIsMarkAll={setIsMarkAll}
        isMarkAll={isMarkAll}
        loading={loading}
        setIsMarkSelected={setIsMarkSelected}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        setIsEditing={setIsEditing}
        isEditing={isEditing}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      {open && (
        <CreateAlert
          busList={busList}
          onCreate={onCreateAlert}
          dashboardId={""}
          dashboardType={() => {}}
          handleClose={() => setOpen(false)}
          open={open}
          handelPhoneVerify={handelPhoneVerify}
        />
      )}
      {isEdit && (
        <EditAlert
          onEdit={onEditAlert}
          open={true}
          handleClose={() => {
            setOpen(false);
            setIsEdit(false);
          }}
          id={isEdit?.id}
          handelPhoneVerify={handelPhoneVerify}
          dashboardType={() => {}}
          title={AlertConstants.editTitle}
        />
      )}

      {isDelete && (
        <AlertDialog
          isOpen={isDelete}
          handleClose={() => {
            setIsDelete(false);
            setIsSaving(false);
          }}
          handleOkay={() => onDeleteAlert(isDelete)}
          title={Constraints.alert}
          description={AlertConstants.deleteTitle}
          isSaving={isSaving}
          isTickerChart={false}
        />
      )}
      {isMarkSelected && (
        <AlertDialog
          isOpen={isMarkSelected}
          handleClose={() => {
            setIsMarkSelected(false);
            setSelectedItems([]);
            setIsSaving(false);
          }}
          handleOkay={() => onReadSelectAlert(isMarkSelected)}
          title={Constraints.alert}
          description={Constraints.alertSelectReadMsg}
          isSaving={isSaving}
        />
      )}
      {isMarkAll && (
        <AlertDialog
          isOpen={isMarkAll}
          handleClose={() => {
            setIsMarkAll(false);
            setIsSaving(false);
          }}
          handleOkay={() => onReadAllAlert()}
          title={Constraints.alert}
          description={Constraints.alertReadMsg}
          isSaving={isSaving}
        />
      )}

      {phoneVerify && (
        <AddPhone
          handleClose={() => setPhoneVerify(false)}
          open={phoneVerify}
          update={setPhoneVerify}
          username={username}
        />
      )}
    </div>
  );
};

Index.propTypes = {
  actions: PropTypes.any,
  userId: PropTypes.string,
  username: PropTypes.string,
};

export default storeConnector(Index, {
  service: "all",
  auth: ["userId", "username"],
});
