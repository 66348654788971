import { Tooltip } from "@mui/material";
import PropTypes from "prop-types";

export const EmailIcon = ({ color, btnClick }) => {
  return (
    <Tooltip
      title="Edit Alert"
      onClick={() => {
        btnClick();
      }}
    >
      <svg
        height="20px"
        width="20px"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
        fill="#000000"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          <path
            style={{ fill: color }}
            d="M512,256.006C512,397.402,397.394,512.004,256.004,512C114.606,512.004,0,397.402,0,256.006 C-0.007,114.61,114.606,0,256.004,0C397.394,0,512,114.614,512,256.006z"
          ></path>
          <path
            style={{ fill: color }}
            d="M512,256.005c0-3.508-0.125-6.987-0.265-10.461c-0.149-0.147-0.292-0.3-0.445-0.445 c-0.37-0.391-0.746-0.766-1.137-1.137c-0.37-0.391-91.744-91.763-92.133-92.133c-5.331-5.632-12.773-9.245-20.918-9.245H116.01 c-16.052,0-30.133,13.595-30.133,29.087V340.57c0,7.89,3.745,15.245,9.53,20.571c0.377,0.385,0.74,0.771,1.137,1.137 c0.377,0.385,148.607,148.639,149.005,149.005c0.155,0.158,0.314,0.309,0.466,0.466c3.319,0.128,6.639,0.252,9.989,0.252 C397.394,512.004,512,397.401,512,256.005z"
          ></path>
          <g>
            <path
              style={{ fill: "#ffffff" }}
              d="M397.101,142.585H116.01c-16.053,0-30.133,13.596-30.133,29.088v168.898 c0,15.367,14.08,28.846,30.133,28.846h281.091c15.731,0,29.022-13.207,29.022-28.846V171.673 C426.123,155.903,412.832,142.585,397.101,142.585z M411.947,340.57c0,7.815-6.937,14.669-14.845,14.669H116.01 c-8.203,0-15.956-7.13-15.956-14.669V171.673c0-7.524,7.902-14.911,15.956-14.911h281.091c7.771,0,14.845,7.109,14.845,14.911 V340.57H411.947z"
            ></path>
            <path
              style={{ fill: "#ffffff" }}
              d="M378.858,188.272c-2.177-3.253-6.573-4.14-9.829-1.966L256,261.655l-113.028-75.349 c-3.264-2.174-7.657-1.287-9.829,1.966c-2.171,3.261-1.291,7.657,1.966,9.829l116.96,77.973c1.19,0.796,2.561,1.19,3.932,1.19 s2.741-0.395,3.932-1.19l116.96-77.973C380.148,195.929,381.027,191.533,378.858,188.272z"
            ></path>
            <path
              style={{ fill: "#ffffff" }}
              d="M180.996,257.323l-49.619,35.442c-3.187,2.271-3.925,6.701-1.651,9.885 c1.385,1.938,3.565,2.97,5.777,2.97c1.426,0,2.866-0.429,4.112-1.323l49.619-35.442c3.187-2.271,3.925-6.701,1.651-9.885 C188.608,255.778,184.171,255.038,180.996,257.323z"
            ></path>
            <path
              style={{ fill: "#ffffff" }}
              d="M331.004,257.323c-3.178-2.285-7.615-1.543-9.889,1.648c-2.274,3.184-1.537,7.615,1.651,9.885 l49.619,35.442c1.246,0.893,2.686,1.323,4.112,1.323c2.211,0,4.393-1.031,5.777-2.97c2.274-3.184,1.537-7.615-1.651-9.885 L331.004,257.323z"
            ></path>
          </g>
        </g>
      </svg>
    </Tooltip>
  );
};
export const SmsIcon = ({ color, btnClick }) => {
  return (
    <Tooltip
      title="Edit Alert"
      onClick={() => {
        btnClick();
      }}
    >
      <svg
        width="20px"
        height="20px"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          <path
            style={{ fill: color }}
            d="M95.464,359.232l321.48-229.216l92.495,89.772C491.845,95.555,385.091,0,256,0 C114.615,0,0,114.615,0,256c0,139.851,112.147,253.493,251.414,255.942L95.464,359.232z"
          ></path>
          <path
            style={{ fill: color }}
            d="M95.464,359.232l155.95,152.71c1.527,0.027,3.053,0.058,4.586,0.058c141.385,0,256-114.615,256-256 c0-12.294-0.886-24.38-2.561-36.212l-92.495-89.772L95.464,359.232z"
          ></path>
          <g>
            <polygon
              style={{ fill: "#FFFFFF" }}
              points="362.736,347.888 362.736,441.272 244.448,347.888 "
            ></polygon>
            <path
              style={{ fill: "#FFFFFF" }}
              d="M404.76,363.872H107.24c-9.552,0-17.376-7.816-17.376-17.376V142.368 c0-9.552,7.816-17.376,17.376-17.376h297.512c9.552,0,17.376,7.816,17.376,17.376v204.136 C422.128,356.056,414.312,363.872,404.76,363.872z"
            ></path>
          </g>
          <g>
            <circle
              style={{ fill: color }}
              cx="174.18"
              cy="212"
              r="24.912"
            ></circle>
            <circle
              style={{ fill: color }}
              cx="256"
              cy="212"
              r="24.912"
            ></circle>
            <circle
              style={{ fill: color }}
              cx="337.82"
              cy="212"
              r="24.912"
            ></circle>
          </g>
          <rect
            x="149.26"
            y="280.49"
            style={{ fill: color }}
            width="213.47"
            height="16"
          ></rect>
        </g>
      </svg>
    </Tooltip>
  );
};

// Prop validation
EmailIcon.propTypes = {
  color: PropTypes.string.isRequired,
  btnClick: PropTypes.func.isRequired,
};
// Prop validation
SmsIcon.propTypes = {
  color: PropTypes.string.isRequired,
  btnClick: PropTypes.func.isRequired,
};
