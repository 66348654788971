import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import { IconButtonStyle } from "../Style";

const IconButtons = ({ handleMoreChart, open, setOpen, data, optionsRef }) => {
  const toggleOptions = (event, data) => {
    const options = optionsRef.current;
    options.style.display = options.style.display = open ? "none" : "block";
    handleMoreChart(event, data);
    setOpen(!open);
  };

  const handleOutsideClick = useCallback(
    (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        optionsRef.current.style.display = "none";
        setOpen(false);
      }
    },
    [optionsRef, setOpen]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <IconButton
      sx={IconButtonStyle.alertTable.sideMenuIcon}
      fontSize="small"
      onClick={(event) => {
        toggleOptions(event, data);
      }}
    >
      <MoreVertIcon />
    </IconButton>
  );
};

IconButtons.propTypes = {
  setOpen: PropTypes.func,
  handleMoreChart: PropTypes.func,
  data: PropTypes.any,
  optionsRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  open: PropTypes.bool,
};

export default IconButtons;
