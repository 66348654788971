import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { connect } from "react-redux";
import thunk from "redux-thunk";
import reducers from "./reducers";
import actions from "./actions";

export function dispatcher(type, payload) {
  return {
    type,
    ...payload,
  };
}
const composeEnhancers = composeWithDevTools({
  // Specify here name, actionsDenylist, actionsCreators and other options
  serialize: true,
});

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

const { history } = store.getState().service || {};

// Ensure `history` exists before trying to use it
if (history) {
  store.dispatch(
    dispatcher("UPDATE_SERVICE_STATE", {
      currentPath: history.location.pathname,
    })
  );
  history.listen((location) => {
    store.dispatch(
      dispatcher("UPDATE_SERVICE_STATE", { currentPath: location.pathname })
    );
  });
}

export const ac = actions;

export default store;

function mapDispatchToProps(dispatch) {
  return { actions: actions(dispatch) };
}

export const storeConnector = (comp, data) => {
  // This is how far i went
  const mapStateToProps = (state, ownProps) => {
    // The state here is the main object
    const usedFields = Object.keys(data).reduce((accumulator, currentValue) => {
      if (data[currentValue] !== "all") {
        const partialState = data[currentValue].reduce((acc, curValue) => {
          acc[curValue] = state[currentValue][curValue];
          return acc;
        }, {});
        return { ...accumulator, ...partialState };
      }
      return { ...accumulator, ...state[currentValue] };
    }, {});
    return { ...usedFields, ...ownProps };
  };
  return connect(mapStateToProps, mapDispatchToProps)(comp);
};
