import { io } from 'socket.io-client';
import { SOCKET_URL } from "../config";

// Production url for socket application
console.log('socket Url V3.0.0.3',SOCKET_URL);
const socket = io(`${SOCKET_URL}/?transport=polling`);

socket.once('connected');

socket.on('connect', () => {
  console.log('socket connected');
});

socket.on('RECEIVE_DATA', (data) => {
  // console.log(data,'RECEIVE_DATA');
});

socket.on('reconnect', async () => {
  console.log('Socket reconnected');
});

export default socket;
