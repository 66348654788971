/* eslint-disable no-case-declarations */
import moment from "moment";
import { ENQUEUE } from "../actions/notifyActions";

const initialState = [];

function notifyReducer(state = initialState, action) {
  switch (action.type) {
    case ENQUEUE:
      const expiryTime = moment().add(1, "minutes");
      return [
        ...state,
        {
          expiryTime,
          payload: action.payload || {},
        },
      ];
    default:
      return state; // Return the current state instead of resetting
  }
}

export default notifyReducer;
