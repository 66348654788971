import React, { useEffect, useState } from "react";
import { Link ,useHistory} from "react-router-dom";
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import "./Login.css";
import "../../App.css";
import { ROUTES } from "../../constant/routes";
import {  LOGIN  } from "../../constant";
import { LOGIN_ERROR } from "../../constant/Login";
import { ENQUEUE } from '../../store/actions/notifyActions';
import { MainTitle, Spinner, Input as LoginInput, Button } from '../../components/Common';
import { storeConnector } from '../../store';

const Login = (props) => {
  const { authLoading,  actions } = props;
  const history = useHistory()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const submitForm = async (email, password) => {
    actions.auth
      .authenticate(
        {
          Username: email,
          Password: password
        },
        history
      ).then(() => {

        const group = localStorage.getItem('group');

        if (group !== null) {
          dispatch({
            type: ENQUEUE,
            payload: {
              message: LOGIN_ERROR.login
            }
          });
        }

      }).catch((err) => {
        console.log('An error occurred when login',err);
        dispatch({
          type: ENQUEUE,
          payload: {
            message: err.message
          }
        });
      });
  };

  useEffect(
    () => () => {
      localStorage.removeItem('token');
    },
    []
  );
  return (
    <div className="login-container">
      <div className="login-box">
      <MainTitle title={LOGIN} />
        <div className="input-group" style={{ display: 'flex', justifyContent: 'center' }}>
          <LoginInput
            class="nd-big-inp"
            label="Email"
            autofocus
            onChange={(value) => {
              setEmail(value);
            }}
            onKeyPress={(event) => {
              if (email !== '' && password !== '' && event.key === 'Enter') {
                submitForm(email, password);
              }
            }}
          >
          </LoginInput>
        </div>

        <div className="input-group" style={{ display: 'flex', justifyContent: 'center' }}>
          <LoginInput
            class="nd-big-inp"
            label="Password"
            type="password"
            onChange={(value) => {
              setPassword(value);
            }}
            onKeyPress={(event) => {
              if (password !== '' && email !== '' && event.key === 'Enter') {
                submitForm(email, password);
              }
            }}
          />
        </div>

        
        <div className="btn-container" style={{ alignSelf: 'center' }}>
        <Link to={ROUTES.FORGOT_PASSWORD}>Forgot password?</Link>
          {authLoading ? (
           <Spinner />
          ) : (
            <Button
              containerClassName="login-button-w-40"
              onClick={() => {
                submitForm(email, password);
              }}
            >
              Login
            </Button>
          )}
        </div>
        </div>
    </div>
  );
};

Login.propTypes = {
  authLoading: PropTypes.bool.isRequired, // Validate authLoading prop
  actions: PropTypes.shape({
    auth: PropTypes.shape({
      authenticate: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
};

export default storeConnector(Login, {
  auth: ['cognitoUser', 'email', 'isEmail','authLoading']
});
