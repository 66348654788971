/* eslint-disable */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import Input from './Input';

library.add(fas);

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDialogShow: false,
      loading: false
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    if (this.props.onConfirm) {
      document.addEventListener('mousedown', this.handleClickOutside);
    }
  }

  componentWillUnmount() {
    if (this.props.onConfirm) {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef) {
      if (!this.wrapperRef.contains(event.target)) {
        this.setState({
          confirmDialogShow: false,
          xPosition: false,
          yPosition: false
        });
      }
    }
  }

  onClick(e) {
    if (this.props.onConfirm) {
      this.setState({
        confirmDialogShow: true
      });
    } else {
      this.props.onClick && this.props.onClick();
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  onClickConfirn() {
    if (
      (this.props.onConfirm.confirmInput &&
        this.props.onConfirm.confirmInputValidation()) ||
      !this.props.onConfirm.confirmInput
    ) {
      this.setState({
        confirmDialogShow: false,
        xPosition: false,
        yPosition: false,
        loading: !!this.props.onConfirm.setLoading
      });
      this.props.onClick && this.props.onClick();
    }
  }

  onCancel() {
    this.setState({
      confirmDialogShow: false,
      xPosition: false,
      yPosition: false
    });
    if (this.props.onConfirm.cancel) {
      this.props.onConfirm.cancel();
    }
  }

  render() {
    const { icon } = this.props;
    return (
      <div className={`btn-container ${this.props.containerClassName || ''}`}>
        {this.props.link ? (
          <NavLink
            className={`btn ${this.props.className || ''} ${
              this.props.small && 'small'
            } ${this.props.active && 'active'}`}
            disabled={this.props.disabled}
            to={this.props.link}
          >
            {icon && (
              <FontAwesomeIcon
                icon={icon}
                className={`${!this.props.children && 'no-margin'}`}
              />
            )}
            {this.props.children}
          </NavLink>
        ) : (
          <button
            type={this.props.type}
            style={
              this.props.buttonStyle && {
                minHeight: '40px',
                minWidth: '120px'
              }
            }
            className={`btn ${this.props.className || ''} ${
              this.props.small && 'small'
            }  ${this.props.active && 'active'} ${
              this.props.onConfirm &&
              (this.state.loading || this.props.onConfirm.alreadyLoading) &&
              'loading'
            }`}
            disabled={this.props.disabled}
            onClick={(e) => {
              this.onClick(e);
            }}
            title={this.props.title ? this.props.title : ''}
          >
            {icon && (
              <FontAwesomeIcon
                icon={icon}
                className={`${!this.props.children && 'no-margin'}`}
              />
            )}
            {(this.state.loading ||
              (this.props.onConfirm && this.props.onConfirm.alreadyLoading)) &&
            this.props.onConfirm.loadingLable
              ? this.props.onConfirm.loadingLable
              : this.props.children}
          </button>
        )}
        {this.props.onConfirm && (
          <div
            className={`confirmation-popup ${
              this.state.confirmDialogShow && 'show'
            }`}
            ref={this.setWrapperRef}
          >
            <div className="title">{this.props.onConfirm.title}</div>
            <div className="msg">{this.props.onConfirm.message}</div>
            {this.props.onConfirm.confirmInput && (
              <Input
                title=""
                type="title"
                noTitle
                placeholder={
                  this.props.onConfirm.confirmInputPlaceholder || ' '
                }
                value={this.props.onConfirm.confirmInputValue}
                onChange={(val) => {
                  this.props.onConfirm.confirmInput(val);
                }}
                id="save-as-model"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    this.onClickConfirn();
                  }
                  if (e.keyCode === 27) {
                    this.onCancel();
                  }
                }}
              />
            )}
            <div className="contnr-btns">
              <button
                type={this.props.type}
                className="btn confirm-btn cancel"
                onClick={(e) => {
                  this.onCancel();
                }}
              >
                <FontAwesomeIcon icon="times" />
                Cancel
              </button>
              <button
                type={this.props.type}
                className="btn confirm-btn accept"
                onClick={(e) => {
                  this.onClickConfirn();
                }}
              >
                <FontAwesomeIcon icon="check" />
                Accept
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default Button;
