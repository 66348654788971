import React, { useState,useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter} from 'react-router-dom';
import {
  ForgotPassword,
  Login,
  ResetPassword,
} from './auth';
import {  ROUTES } from './constant/routes';
import { storeConnector } from './store';
import { PrivateRoute, PublicRoute } from './routes';
import NavigationContainer from './container/Navigation'
import { Layouts } from "./components/Common";
import { ThemeContext } from "./components/Common/Theme/Context";
import { useAuth } from './auth/Context/AuthContext';
const MainRoutes = ({
  actions,
  cognitoUser,
  authLoading,
  history
}) => {
  const [group, setGroup] = useState(localStorage.getItem('group'));
  const { theme } = useContext(ThemeContext);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    setGroup(localStorage.getItem('group'));
  }, []);

  useEffect(() => {
    localStorage.setItem('group', group);
  }, [group]);

  return (
    <BrowserRouter>
        <Layouts isAuthenticated={isAuthenticated} theme={theme} />
      <div>
        <PublicRoute
          exact
          path={ROUTES.RESET_PASSWORD}
          component={(props) => (
            <ResetPassword
              {...props}
              cognitoUser={cognitoUser}
              history={history}
              actions={actions}
            />
          )}
        />
        <PublicRoute
          exact
          restricted
          path={ROUTES.LOGIN}
          component={() => (
            <Login
              authLoading={authLoading}
              actions={actions}
              history={history}
            />
          )}
        />
        <PublicRoute
          exact
          path={ROUTES.FORGOT_PASSWORD}
          component={() => (
            <ForgotPassword actions={actions} history={history} />
          )}
        />
        <PrivateRoute component={NavigationContainer} />
        </div>

        </BrowserRouter>
  );
};

MainRoutes.propTypes = {
  actions: PropTypes.object.isRequired,
  cognitoUser: PropTypes.object,
  authLoading: PropTypes.bool.isRequired,
  history: PropTypes.object,
};

export default storeConnector(MainRoutes, {
  auth: 'all',
  service: ['history']
});
