import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  FaHome,
  FaChartBar,
  FaSignOutAlt,
  FaBars,
  FaRegWindowClose,
} from "react-icons/fa"; // Import icons
import { IoIosNotifications } from "react-icons/io";
import "./NavigationBar.css"; // We'll create this CSS file
import { PRIVATE_ROUTES, ROUTE_TEXTS } from "../../../../constant/routes";
import { storeConnector } from "../../../../store";
import { formatViewerCount } from "../../../Analyst/Utils";

const NavigationBar = ({ logout, isMenuOpen, setIsMenuOpen, toggleMenu, alertCount }) => {

  return (
    <nav className="navigation-bar">
      <button
        className="menu-bar"
        aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        onClick={() => toggleMenu()}
      >
        {isMenuOpen ? <FaRegWindowClose size={24} /> : <FaBars size={24} />}
      </button>

      <div className={`nav-links ${isMenuOpen ? "open" : ""}`}>
        <Link
          to={PRIVATE_ROUTES.HOME}
          onClick={() => setIsMenuOpen(false)}
          className="nav-icon"
        >
          <FaHome
            data-tooltip-id="tooltip"
            data-tooltip-content={ROUTE_TEXTS.HOME}
            size={24}
          />
          <span>{ROUTE_TEXTS.HOME}</span>
        </Link>
        <Link
          to={PRIVATE_ROUTES.REPORT}
          onClick={() => setIsMenuOpen(false)}
          className="nav-icon"
        >
          <FaChartBar
            data-tooltip-id="tooltip"
            data-tooltip-content={ROUTE_TEXTS.REPORT}
            size={24}
          />
          <span>{ROUTE_TEXTS.REPORT}</span>
        </Link>
        <Link
          to={PRIVATE_ROUTES.ANALYST}
          onClick={() => setIsMenuOpen(false)}
          className="nav-icon"
        >
          <IoIosNotifications
            data-tooltip-id="tooltip"
            data-tooltip-content={ROUTE_TEXTS.ALERT}
            size={24}
            fill={alertCount > 0 ? "red" : "var(--text-color)"}
          />
          {alertCount > 0 && (
            <span
              className="badge"
              style={{color: "red"}}
            >
              {formatViewerCount(alertCount)}
            </span>
          )}
          <span>{ROUTE_TEXTS.ALERT}</span>
        </Link>
        <button
          onClick={() => {
            logout();
            setIsMenuOpen(false);
          }}
          className="nav-icon logout-btn"
        >
          <FaSignOutAlt
            place
            data-tooltip-id="tooltip"
            data-tooltip-content={ROUTE_TEXTS.LOGOUT}
            size={24}
          />
          <span>{ROUTE_TEXTS.LOGOUT}</span>
        </button>
      </div>
    </nav>
  );
};

NavigationBar.propTypes = {
  logout: PropTypes.func, // Update to expect a function
  isMenuOpen: PropTypes.bool,
  setIsMenuOpen: PropTypes.func.isRequired, // It should be a function, not a string
  toggleMenu: PropTypes.func.isRequired,
  alertCount: PropTypes.number,
};

export default storeConnector(NavigationBar, {
  auth: ["userId"],
  service: ["history"],
  alert: ["alertCount"],
});
