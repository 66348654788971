import React from "react";
import PropTypes from "prop-types";

const Index = ({ label }) => {
  return <div style={{ color: "#FF0000" }}>{label}</div>;
};

// PropTypes for validation
Index.propTypes = {
  label: PropTypes.string.isRequired,
};

export default Index;
