import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons"; // Ensure this is exported from your `icons.js`

const RefreshButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="refresh-button"
      style={{ background: "none", border: "none", cursor: "pointer" }}
    >
      <FontAwesomeIcon icon={faSync} size="sm" />
    </button>
  );
};

export default RefreshButton;
