import { destroy, store, update, fetch } from "./httpUtil";

// Alerts
export const getAlerts = (data) => {
  return new Promise(async (resolve, reject) => {
    return await store(`alerts/all`)
      .then((result) => {
        if (result.status === 200) {
          resolve(result.data);
        } else {
          resolve([]);
        }
      })
      .catch((error) => {
        resolve([]);
      });
  });
};

export const addAlert = (data) => {
  return new Promise(async (resolve, reject) => {
    return await store(`alert/add`, data)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log("Add Alert error", error);
        reject(error);
      });
  });
};

export const updateAlert = (data) => {
  return new Promise(async (resolve, reject) => {
    return await update(`alert/edit`, data)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        resolve([]);
      });
  });
};

export const deleteAlert = (data) => {
  return new Promise(async (resolve, reject) => {
    return await destroy(`alert/delete`, {
      data: {
        id: data.id,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("Alert Delete error", error);
        resolve([]);
      });
  });
};

export const readAllAlerts = () => {
  return new Promise(async (resolve, reject) => {
    return await update(`alert/user/notifications/read`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("read user notification alert error", error);
        reject(error);
      });
  });
};

export const readSelectedAlerts = (data) => {
  return new Promise(async (resolve, reject) => {
    return await update(`alert/notifications/read`, data)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.log("read notification alert error", error);
        reject(error);
      });
  });
};

export const updateCredit = (data) => {
  return new Promise(async (resolve, reject) => {
    return await store(`user/credits/add`, data)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const userPhoneUpdate = (data) => {
  return new Promise(async (resolve, reject) => {
    return await store(`user/phone/update`, data)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const sendPhoneOTP = (data) => {
  return new Promise(async (resolve, reject) => {
    return await store(`send/OTP`, data)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const verifyPhoneOTP = (data) => {
  return new Promise(async (resolve, reject) => {
    return await store(`verify/OTP`, data)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const getUserDetail = (data) => {
  return new Promise(async (resolve, reject) => {
    return await store(`user`, data)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateAdminCredit = (data) => {
  return new Promise(async (resolve, reject) => {
    return await store(`user/alert/status`, data)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getGridLocationsByStream = (stream) => {
  return new Promise(async (resolve, reject) => {
    return await fetch(`grid/${stream}`)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log("Get node name error", error);
        resolve([]);
      });
  });
};

export const getStreamNames = (data) => {
  return new Promise(async (resolve, reject) => {
    return await fetch(`streams`)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log("Get stream name  error", error);
        reject(error);
      });
  });
};
