/* eslint-disable no-confusing-arrow */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { storeConnector } from '../../store'
import { PRIVATE_ROUTES } from '../../constant/routes'
import { useAuth } from '../../auth/Context/AuthContext';

// restricted = false meaning public route
// restricted = true meaning restricted route
const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const { isAuthenticated } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && restricted ? (
          <Redirect to={PRIVATE_ROUTES.HOME} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
PublicRoute.propTypes = {
  component: PropTypes.any,
  restricted: PropTypes.bool,
  cognitoUser: PropTypes.object
};
export default storeConnector(PublicRoute, {
  auth: ['group', 'cognitoUser'],
  service: 'all'
});
