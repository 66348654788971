export const SAVE = "Save";

export const USER = {
  SettingAlert: "Setting Alerts",
};

export const USER_ACCESS_LEVEL = {
  VIEWER: "Viewer",
  ANALYST: "Analyst",
  TRADER: "Trader",
};

