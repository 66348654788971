/**
 * @desc Returns the grid name that matches the given title or null if none is found
 * @param {String,Object}
 * @returns {String} stream name
 */
export const getGridName = (title, gridLocations) => {
  if (title) {
    const matchedStream = gridLocations?.find(
      (stream) => stream?.value === title
    );
    return matchedStream ? matchedStream?.label : null;
  }
  return null;
};

/**
 * @desc Returns the label of the stream with the given title if there is one. Returns null
 * @param {String,Object}
 * @returns {String} stream name
 */
export const getStreamName = (title, selectedNode, streams) => {
  if (title) {
    const matchedStream = streams?.find((stream) => stream?.name === title);
    return matchedStream ? matchedStream.name : null;
  }
  return null;
};

/**
 * @desc This is useful for Autocomplete components to ensure that the correct item is
 * rendered when an option is selected.
 * @param {Object} option - The option object from the list of available options.
 * @param {string} value - The currently selected value in the Autocomplete.
 * @returns {boolean} - Returns true if the option's value matches the selected value (case-insensitive).
 */
export const isOptionEqualToValue = (option, value) =>
  option?.value?.toLowerCase() === value?.toLowerCase();

export function formatViewerCount(viewers) {
  if (viewers >= 1000000) {
    return (viewers / 1000000).toFixed(1) + 'M+';
  } else if (viewers >= 1000) {
    return (viewers / 1000).toFixed(1) + 'K+';
  } else {
    return viewers.toString();
  }
};