import React, { useState } from "react";
import { LogoImgDark, LogoImgLight } from "../../../../assets/images";

import PropTypes from "prop-types";
import ThemeToggle from "../../Theme/Toggle";
import NavigationBar from "../NavigationBar";
import { useAuth } from "../../../../auth/Context/AuthContext";

const Header = ({ theme }) => {
  const { isAuthenticated, logout } = useAuth();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="App-header">
      <img
        src={theme === "dark" ? LogoImgLight : LogoImgDark}
        className="logo-img"
        alt="fractl-logo-img"
        loading="lazy"
      />
      <ThemeToggle />

      {isAuthenticated && (
        <NavigationBar
          logout={logout}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          toggleMenu={toggleMenu}
        />
      )}
    </header>
  );
};
Header.propTypes = {
  theme: PropTypes.string.isRequired,
};
export default Header;
