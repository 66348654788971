/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import AWS from 'aws-sdk';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'; // eslint-disable-line

export const slsConfig = require(`../sls-stack-output-${process.env.REACT_APP_ENV}.json`);

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
  region: process.env.REACT_APP_REGION
});

export const userPool = new AmazonCognitoIdentity.CognitoUserPool({
  UserPoolId: slsConfig.CognitoUserPoolId,
  ClientId: slsConfig.CognitoClientId
});
