import AWS from 'aws-sdk';
import store, { dispatcher } from  "../../store";
import { slsConfig, userPool } from "../../services";
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { notifyError } from "./serviceActions";
import { PRIVATE_ROUTES, ROUTES,match_urls } from "../../constant/routes";
const auth = {
  updateClient(data) {
    return function (dispatch) {
      dispatch(
        dispatcher('UPDATE_STATE', {
          client: data
        })
      );
    };
  },

  refreshAuthTokens() {
    // Check if the session is expired
    return function (dispatch) {
      try {

        const state = store.getState();
        state.auth.currentSession()
          .then((data) => {
            let accessToken = data.getAccessToken();
            let expiresIn = accessToken.getExpiration();
            let currentTime = Math.floor(new Date() / 1000);
            if (currentTime > expiresIn - 300) {
              auth.refreshSession();
            }
          })
          .catch((error) => {
            console.error("Error fetching session", error);
          });
      } catch (e) {
        console.log("Failed to refresh tokens", e);
      }
    };
  },

  refreshSession() {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const state = store.getState();
        state.auth.cognitoUser.getSession((err, session) => {
          if (err || !session) {
            reject(err.message);
          } else {
            window.onCredentionalsReady = null;
            window.onCredentionalsReady = new Promise((resolveCred) => {
              window.noCredForToken = true;
              state.auth.cognitoUser.refreshSession(
                session.getRefreshToken(),
                (err, session2) => {
                  if (err || !session2) {
                    reject(err.message);
                  } else {
                    dispatch(auth.getCredentials(session2, true))
                      .then(() => {
                        resolveCred();
                        window.noCredForToken = false;
                        resolve();
                      })
                      .catch((e) => {
                        reject();
                      });
                  }
                }
              );
            });
          }
        });
      });
    };
  },


  getCredentials(session, noloading = false) {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        if (!noloading) {
          dispatch(
            dispatcher('LOADING', {
              getAWSCredentials: true
            })
          );
        }
        const idToken = session.getIdToken(); // will return new one only if refresh called when expired

        const authJwtToken = idToken.jwtToken;
        // const authSub = idToken.payload.sub;
        dispatch(
          dispatcher('UPDATE_STATE', {
            authJwtToken
          })
        );
        // todo try to compare with old token and reget credentials only if token was changed, then add init user for each backend call
        // it should be fast for check token expired and long if expired (once per an hour), then remove every hour refresh!
        const providerKey = `cognito-idp.${slsConfig.Region}.amazonaws.com/${slsConfig.CognitoUserPoolId}`;
        AWS.config.region = slsConfig.Region;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: slsConfig.CognitoIdentityPoolId,
          Logins: {
            [providerKey]: authJwtToken
          }
        });
        AWS.config.credentials.get((error) => {
          if (!noloading) {
            dispatch(
              dispatcher('LOADING', {
                getAWSCredentials: false
              })
            );
          }
          if (error) {
            //store.dispatch(notifyError(`Can't authorize user: ${error}`));
            store.dispatch(
              notifyError(`Something went wrong. Please try again`)
            );

            reject(error);
          }
          if (!AWS.config.credentials.accessKeyId) {
            reject('accessKeyId after get credentials is undefined');
          }
      
          dispatch(
            dispatcher('LOGIN_SUCCESS', {
              userdata: session,
              functionName: 'getCredentials'
            })
          );

         
            resolve();
          
        });
      });
    };
  },

  verifyUser(data) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
          Username: data.userName,
          Pool: userPool
        });

        cognitoUser.confirmRegistration(
          data.confirmationCode,
          true,
          (err, result) => {
            if (err) {
              reject(err.message);
              return;
            }
            resolve(result);
          }
        );
      });
  },

  verifyEmail(data) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        // verification should be done on authenticated user only!
        store.getState().auth.cognitoUser.getSession(() => {
          store
            .getState()
            .auth.cognitoUser.verifyAttribute('email', data.confirmationCode, {
              onSuccess(result) {
                resolve(result);
              },
              onFailure(err) {
                reject(err.message);
              }
            });
        });
      });
  },

  initUser(noloading = false) {
    return function (dispatch) {

      return new Promise((resolve, reject) => {
        if (!noloading) {
          dispatch(
            dispatcher('LOADING', {
              login: true
            })
          );
        }
        const state = store.getState();

        if (!window.cognitoUserArr) {
          window.cognitoUserArr = [];
        }
        window.cognitoUserArr.push(state.auth.cognitoUser);
        window.cognitoUser = state.auth.cognitoUser;

       
        if (state.auth.cognitoUser != null) {
          state.auth.cognitoUser.getSession((err, session) => {
            window.globalEmit.fireUserLoaded();
            if (!noloading) {
              dispatch(
                dispatcher('LOADING', {
                  login: false
                })
              );
            }
            if (err || !session) {

              if (err && err.code === 'NetworkError') {
                store.dispatch(
                  notifyError(`Auth session validation error ${err}`)
                );
                reject();
              } else {
                dispatch(auth.logout());
              }
              return;
            }
           
            if (session.isValid) {
              dispatch(auth.getCredentials(session, noloading))
                .then(() => {
                  console.log(' state.service.currentPath', state.service.currentPath)
                  if (match_urls(state.service.currentPath)) {
                    store.getState().service.history.push(PRIVATE_ROUTES.HOME);
                  } 
                  resolve();
                })
                .catch((err) => {
                  console.log('Error: ' + err.message)
                  // reject();
                });
            } else {
              window.location.href = ROUTES.LOGIN;
              resolve();
            }
          });
        } else {
          if (!noloading) {
            dispatch(
              dispatcher('LOADING', {
                login: false
              })
            );
          }


          
       

          if (match_urls(window.location.pathname) === undefined) {
            console.log(
              'redirecting to login from',
              state.service.history.location.pathname
            );
            window.location.href=ROUTES.LOGIN
            resolve();

          }
        }
      });
    };
  },


  hardLogoutWithError(message) {
    return function (dispatch) {
      return new Promise(async (resolve, reject) => {
        //* Fix:  When user account is expired, then he will not able to login
        const customError = new Error(message);
        // const customError = new Error('Account expired. Please contact to site administrator');

        dispatch(
          dispatcher("AUTH_LOADING", {
            authLoading: false,
          })
        );
        dispatch(
          dispatcher("LOGIN_ERROR", {
            customError,
          })
        );
        dispatch(auth.logout());
        reject(customError);
      });
    };
  },

  softLogout() {
    return function (dispatch) {
      store.getState().auth.cognitoUser.signOut();

      dispatch(
        dispatcher("UPDATE_STATE", {
          user: {
            username: "",
          },
        })
      );

      try {
        if (AWS.config.credentials) {
          AWS.config.credentials.clearCachedId();
        }
      } catch (e) {}
    

    
      
    };
  },
  logout(firstLogin) {
    return function (dispatch) {
      store.getState().auth.cognitoUser.signOut();
   
      dispatch(dispatcher('LOADING', { login: true }));
      dispatch(
        dispatcher('UPDATE_STATE', {
          user: {
            username: ''
          }
        })
      );
      dispatch(dispatcher('LOGOUT'));
      if (AWS.config.credentials) {
        AWS.config.credentials.clearCachedId();
      }

      window.location.href="/login"

    };
  },

  authenticate(data, history) {
    return function (dispatch) {
      return new Promise(async (resolve, reject) => {
        dispatch(
          dispatcher('AUTH_LOADING', {
            authLoading: true
          })
        );
        const s = store.getState().service;
        s.loginError = '';
        dispatch(dispatcher('UPDATE_STATE', { service: s }));

        let currentAccessToken = null;

        if (
          store.getState().auth.cognitoUser &&
          store.getState().auth.cognitoUser.getSignInUserSession()
        ) {
          currentAccessToken = store
            .getState()
            .auth.cognitoUser.getSignInUserSession()
            .getAccessToken()
            .getJwtToken();
          localStorage.setItem("currentAccessToken", currentAccessToken);
        }

        store.getState().auth.cognitoUser =
          new AmazonCognitoIdentity.CognitoUser({
            Username: data.Username,
            Pool: userPool
          });
        store
          .getState()
          .auth.cognitoUser.setAuthenticationFlowType('USER_SRP_AUTH');
        if (!data.Password && data.AdminLogin) {
          store
            .getState()
            .auth.cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
        }
        const authenticationDetails =
          new AmazonCognitoIdentity.AuthenticationDetails({
            Username: data.Username,
            Password: data.Password
          });

        const callbacks = {
          onSuccess(session) {
            dispatch(
              dispatcher('LOGIN_SUCCESS', {
                userdata: session,
                functionName: 'authenticate'
              })
            );
            /* if(store.getState().auth.username && session.idToken.payload['cognito:username'] !==
            store.getState().auth.username) {
                            store.getState().auth.cognitoUser.signOut();
                            dispatch(dispatcher('UPDATE_STATE', {
                                user: {
                                    username: ''
                                },
                            }));
                            if (AWS.config.credentials) {
                                AWS.config.credentials.clearCachedId();
                            }
                        } */

            // var accessToken = session.accessToken.getJwtToken()
            const accessToken = session.getIdToken().getJwtToken();
            localStorage.setItem('token',accessToken)
            // POTENTIAL: Region needs to be set if not already set previously elsewhere.
            AWS.config.region = slsConfig.Region;

            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
              IdentityPoolId: slsConfig.CognitoIdentityPoolId, // your identity pool id here
              Logins: {
                // Change the key below according to the specific region your user pool is in.
                [`cognito-idp.${slsConfig.Region}.amazonaws.com/${slsConfig.CognitoUserPoolId}`]:
                  accessToken
              }
            });

            // refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
            AWS.config.credentials.refresh((error) => {
              // debugger;
              if (error) {
                console.log(error);
              } else {
                // Instantiate aws sdk service objects now that the credentials have been updated.
                // example: var s3 = new AWS.S3();
                console.log('Successfully logged!');
              }
            });
            // debugger;
            // var tmpData = store.getState().auth.group ;
            dispatch(auth.getCredentials(session)).then(() => {
              if (
                !store.getState().auth.group ||
                store.getState().auth.group === 'NotSelected'
              ) {
                if (!store.getState().auth.group) {
                  const firstLogin = true;
                  dispatch(auth.logout(firstLogin));
                  store.getState().service.history.push(ROUTES.NOT_APPROVED);
                  history.push(ROUTES.NOT_APPROVED);
                } else {
                  store.getState().service.history.push(PRIVATE_ROUTES.HOME);
                  history.push(PRIVATE_ROUTES.HOME);
                }
              } else {
                history.push(PRIVATE_ROUTES.HOME);
                store.getState().service.history.push(PRIVATE_ROUTES.HOME);
               
              }
              dispatch(
                dispatcher('AUTH_LOADING', {
                  authLoading: false
                })
              );
              resolve();
            });
          },
          onFailure: (err) => {
            dispatch(
              dispatcher('AUTH_LOADING', {
                authLoading: false
              })
            );
            dispatch(
              dispatcher('LOGIN_ERROR', {
                err
              })
            );
            reject(err);
          },
          newPasswordRequired: () => {
            dispatch(
              dispatcher('AUTH_LOADING', {
                authLoading: false
              })
            );
            store.getState().service.history.push(ROUTES.RESET_PASSWORD);
            history.push(ROUTES.RESET_PASSWORD);
            resolve();
          }
        };

        if (
          store.getState().auth.cognitoUser.getAuthenticationFlowType() ===
          'CUSTOM_AUTH'
        ) {
          store.getState().auth.cognitoUser.signOut();
          dispatch(
            dispatcher('UPDATE_STATE', {
              user: {
                username: ''
              }
            })
          );
          if (AWS.config.credentials) {
            AWS.config.credentials.clearCachedId();
          }
          auth.authenticateCustomAuth(
            currentAccessToken,
            authenticationDetails,
            callbacks
          );
        } else {
          store
            .getState()
            .auth.cognitoUser.authenticateUser(
              authenticationDetails,
              callbacks
            );
        }
      });
    };
  },

  authenticateCustomAuth(accessToken, authDetails, callback) {
    const { cognitoUser } = store.getState().auth;

    const authenticationHelper = new AmazonCognitoIdentity.AuthenticationHelper(
      cognitoUser.pool.getUserPoolId().split('_')[1]
    );
    const dateHelper = new AmazonCognitoIdentity.DateHelper();

    let serverBValue;
    let salt;
    const authParameters = {};

    if (cognitoUser.deviceKey != null) {
      authParameters.DEVICE_KEY = window.cognitoUser.deviceKey;
    }

    authParameters.USERNAME = cognitoUser.username;
    authenticationHelper.getLargeAValue((errOnAValue, aValue) => {
      // getLargeAValue callback start
      if (errOnAValue) {
        callback.onFailure(errOnAValue);
      }

      authParameters.SRP_A = aValue.toString(16);

      if (
        store.getState().auth.cognitoUser.authenticationFlowType ===
        'CUSTOM_AUTH'
      ) {
        authParameters.CHALLENGE_NAME = 'SRP_A';
      }

      const jsonReq = {
        AuthFlow: cognitoUser.authenticationFlowType,
        ClientId: cognitoUser.pool.getClientId(),
        AuthParameters: authParameters,
        ClientMetadata: authDetails.getValidationData()
      };
      if (cognitoUser.getUserContextData(cognitoUser.username)) {
        jsonReq.UserContextData = cognitoUser.getUserContextData(
          cognitoUser.username
        );
      }

      cognitoUser.client.request('InitiateAuth', jsonReq, (err, data) => {
        if (err) {
          return callback.onFailure(err);
        }

        const challengeParameters = data.ChallengeParameters;
        const nextChallenge = data.ChallengeName;

        // Since BigInteger class is not exported from AmazonCognitoIdentity module,
        //  to get it's constructor we get already created instance of BigInteger from AuthenticationHelper
        //  and saving constructor parameter that we can use to create more instances of BigInteger
        const BigInteger = new AmazonCognitoIdentity.AuthenticationHelper().N
          .constructor;

        cognitoUser.username = challengeParameters.USER_ID_FOR_SRP;
        serverBValue = new BigInteger(challengeParameters.SRP_B, 16);
        salt = new BigInteger(challengeParameters.SALT, 16);
        cognitoUser.getCachedDeviceKeyAndPassword();

        authenticationHelper.getPasswordAuthenticationKey(
          cognitoUser.username,
          authDetails.getPassword(),
          serverBValue,
          salt,
          (errOnHkdf, hkdf) => {
            // getPasswordAuthenticationKey callback start
            if (errOnHkdf) {
              callback.onFailure(errOnHkdf);
            }

            const dateNow = dateHelper.getNowString();

            const challengeResponses = {};

            challengeResponses.USERNAME = cognitoUser.username;
            challengeResponses.TIMESTAMP = dateNow;
            challengeResponses.ANSWER = accessToken;

            if (cognitoUser.deviceKey != null) {
              challengeResponses.DEVICE_KEY = cognitoUser.deviceKey;
            }
            const respondToAuthChallenge = (challenge, challengeCallback) =>
              cognitoUser.client.request(
                'RespondToAuthChallenge',
                challenge,
                (errChallenge, dataChallenge) => {
                  if (
                    errChallenge &&
                    errChallenge.code === 'ResourceNotFoundException' &&
                    errChallenge.message.toLowerCase().indexOf('device') !== -1
                  ) {
                    challengeResponses.DEVICE_KEY = null;
                    cognitoUser.deviceKey = null;
                    cognitoUser.randomPassword = null;
                    cognitoUser.deviceGroupKey = null;
                    cognitoUser.clearCachedDeviceKeyAndPassword();
                    return respondToAuthChallenge(challenge, challengeCallback);
                  }
                  return challengeCallback(errChallenge, dataChallenge);
                }
              );

            const jsonReqResp = {
              ChallengeName: nextChallenge,
              ClientId: cognitoUser.pool.getClientId(),
              ChallengeResponses: challengeResponses,
              Session: data.Session
            };
            if (cognitoUser.getUserContextData()) {
              jsonReqResp.UserContextData = cognitoUser.getUserContextData();
            }
            respondToAuthChallenge(
              jsonReqResp,
              (errAuthenticate, dataAuthenticate) => {
                if (errAuthenticate) {
                  return callback.onFailure(errAuthenticate);
                }
                return cognitoUser.authenticateUserInternal(
                  dataAuthenticate,
                  authenticationHelper,
                  callback
                );
              }
            );
            return undefined;
            // getPasswordAuthenticationKey callback end
          }
        );
        return undefined;
      });
      // getLargeAValue callback end
    });
  },
  sendResetPassRequest(data) {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        dispatch(
          dispatcher('SET_USER_LOADING', {
            loading: true
          })
        );
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
          Username: data.Username,
          Pool: userPool
        });
        cognitoUser.forgotPassword({
          onSuccess: (result) => {
            resolve();
            dispatch(
              dispatcher('SET_USER_LOADING', {
                loading: false
              })
            );
          },
          onFailure: (err) => {
            reject(err);
            dispatch(
              dispatcher('SET_USER_LOADING', {
                loading: false
              })
            );
          }
        });
      });
    };
  },

  confirmPassReset(data) {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
          Username: data.username,
          Pool: userPool
        });
        cognitoUser.confirmPassword(data.code, data.newPassword, {
          onSuccess: (result) => {
            resolve();
          },
          onFailure: (err) => {
            reject(err);
          }
        });
      });
    };
  },

  changePass(data) {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        dispatch(
          dispatcher('SET_USER_LOADING', {
            loading: true
          })
        );
        store
          .getState()
          .auth.cognitoUser.changePassword(
            data.oldPass,
            data.newPass,
            (err, result) => {
              if (err) {
                dispatch(
                  dispatcher('SET_USER_LOADING', {
                    loading: false
                  })
                );
                reject(err);
              } else {
                dispatch(
                  dispatcher('SET_USER_LOADING', {
                    loading: false
                  })
                );
                resolve();
              }
            }
          );
      });
    };
  },
  completeNewPasswordChallenge(data) {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const s = store.getState().service;
        s.loginError = '';
        dispatch(
          dispatcher('UPDATE_STATE', {
            service: s
          })
        );
        store.getState().auth.cognitoUser.completeNewPasswordChallenge(
          data.password,
          {},
          {
            onSuccess: (session) => {
               console.log('Complete new password challenge', session);
                dispatch(auth.getCredentials(session)).then(() => {
                  store.getState().service.history.push(PRIVATE_ROUTES.HOME);
                  window.location.assign(PRIVATE_ROUTES.HOME);
                });
                resolve();
              
            },
            onFailure: (err) => {
              dispatch(dispatcher('LOGIN_ERROR', { err }));
              reject(err);
            }
          }
        );
      });
    };
  },

  isUserExist({ username: Username }) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        const authenticationData = {
          Username,
          Password: 'Password'
        };
        const authenticationDetails =
          new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

        const userData = {
          Username: 'username',
          Pool: userPool
        };
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: () => {
            resolve();
          },
          onFailure: (err) => {
            if (err.name === 'UserNotFoundException') {
              reject();
            } else {
              resolve();
            }
          }
        });
      });
  }
};

export default auth;