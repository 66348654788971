import moment from "moment";

const initialState = {
  loading: false,
  userCredit: 0,
  user_country: "",
  user_phone_number: "",
  user_deciphered_number: "",
  user_email: "",
  usersList: [],
  usersData: [],
};

const userReducer = (
  state = {
    ...initialState,
  },
  action
) => {
  const handlers = {
    SET_USER_LOADING: () => ({
      ...state,
      loading: action.loading,
    }),
    SET_CREDIT: () => ({
      ...state,
      userCredit: action.payload[0]?.credits || 0,
      user_country: action.payload[0]?.country || "",
      user_phone_number: action.payload[0]?.phoneNumber || "",
      user_deciphered_number: action.payload[0]?.decipheredNumber || "",
      user_email: action.payload[0]?.emailId || "",
    }),
    SET_CREDIT_COUNTS: () => ({
      ...state,
      userCredit: action.payload,
    }),
    GET_USERS_COMPLETE: () => ({
      ...state,
      loading: false,
      usersList: action.user.map((u) => ({
        ...u,
        foldergroup: u.foldergroup || "",
      })),
    }),
    GET_USERS_DATA: () => {
      const newState = {
        ...state,
      };
      const users = action.user.map((user) => {
        const userAccountname = user?.accountname.split(" ");
        let userFirstLast = {
          firstname: "",
          lastname: "",
        };
        if (userAccountname?.length === 1) {
          userFirstLast = { firstname: userAccountname[0], lastname: "" };
        }
        if (userAccountname?.length === 2) {
          userFirstLast = {
            firstname: userAccountname[0],
            lastname: userAccountname[1],
          };
        }
        if (userAccountname?.length === 3) {
          userFirstLast = {
            firstname: `${userAccountname[0]} ${userAccountname[1]}`,
            lastname: userAccountname[2],
          };
        }
        if (userAccountname?.length === 4) {
          userFirstLast = {
            firstname: `${userAccountname[0]} ${userAccountname[1]}`,
            lastname: `${userAccountname[2]} ${userAccountname[3]}`,
          };
        }

        let activeOrExpired = "";
        if (user?.expiredate && new Date(user?.expiredate) > Date.now()) {
          activeOrExpired = "Active";
        } else if (
          user?.expiredate &&
          new Date(user?.expiredate) < Date.now()
        ) {
          activeOrExpired = "Expired";
        } else {
          activeOrExpired = "";
        }

        const notificationDate =
          user?.expiredate &&
          moment(user?.expiredate).subtract(1, "months").format("YYYY-MM-DD");

        return {
          firstname: userFirstLast.firstname,
          lastname: userFirstLast.lastname,
          accounttype: user?.accounttype,
          chargoverid: user?.chargoverid,
          chargovertoken: user?.chargovertoken,
          addin_access_level: user?.addin_access_level,
          autorenewal_selected: user?.autorenewal_selected,
          company: user?.company,
          email: user?.email,
          emailVerified: user?.emailVerified,
          phone_number: user?.phone_number || "",
          phone_number_verified: user?.phone_number_verified || "",
          ems: user?.ems?.target || "",
          enabled: user?.enabled,
          startDate:
            user?.startDate && moment(user?.startDate).format("YYYY-MM-DD"),
          expiredate:
            user?.expiredate && moment(user?.expiredate).format("YYYY-MM-DD"),
          foldergroup: user?.foldergroup,
          group: user?.group,
          status: user?.status,
          username: user?.username,
          activeOrExpired: activeOrExpired || "",
          notificationDate: notificationDate || "",
          licenseType: user?.licenseType,
          userId: user?.sub,
        };
      });
      newState.usersData = users;
      return newState;
    },
    GET_COMPANY_LIST: () => {
      const newState = {
        ...state,
      };
      const companies = action.user
        .filter((u) => u.company !== "")
        .map((c) => c.company);

      const companyArr = [];
      companies.map((comp) => {
        if (!companyArr.includes(comp)) {
          companyArr.push(comp);
        }
        return true;
      });

      newState.companyList = companyArr.sort();
      return newState;
    },
  };
  if (!handlers[action.type]) {
    return state;
  }
  return handlers[action.type]();
};

export default userReducer;
