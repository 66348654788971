/* eslint-disable no-confusing-arrow */
import React, {  useEffect,useState } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { storeConnector } from '../../store';
import { ROUTES } from '../../constant/routes';
import { useAuth } from '../../auth/Context/AuthContext';
import socket from '../../services/socket';

let count = 0;

const PrivateRoute = (props) => {
  const {
    cognitoUser,
    component: Component,
    email,
    actions,
    email_verified,
    userId,
    ...rest
  } = props;

  const { isAuthenticated} = useAuth();
  const [connected, setConnected] = useState(false)
  const location = useLocation()
  useEffect(() => {
      const handleVisibilityChange = () => {
        console.log('isConnected: ', document.hidden,connected );
        if (!document.hidden ) {
          // setTimeout(() => {
          // },1000)
            socket.connect()
            socket.emit("JOIN_ALERT_ROOM", {
              userId: userId,
            });
        } 
      };
      document.addEventListener('visibilitychange', handleVisibilityChange);
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
  }, [document.hidden, userId]);

  useEffect(() => {
    let timeoutId;
    count++;

    if(userId) {

      socket.io.on('error', (error) => {
        console.log('Socket Error');
        setConnected(false);
        setTimeout(() => {
          socket.connect()
          socket.emit("JOIN_ALERT_ROOM", {
            userId: userId,
          });
        },5000)
      });

      socket.io.on('reconnect', (attempt) => {
        console.log('Socket reconnect');
      });

      socket.io.on('disconnect', () => {
          console.log('Disconnected from server');
      });

      socket.on('connect', () => {
        console.log('socket connected again');
        setConnected(true);
        socket.emit("JOIN_ALERT_ROOM", {
          userId: userId,
        });
      });
      socket.emit("JOIN_ALERT_ROOM", {
        userId: userId,
      });

      // Display unread notification count
      socket.on("ALERT_UNREAD_COUNTS", async (res) => {
        console.log('ALERT_UNREAD_COUNTS res: ', res.data)
        if (res?.code === 200) {
          actions.alert.setAlertUnreadCount(res.data);
        }
      });
    }

    if ((count === 1) & isAuthenticated) {
      cognitoUser?.getUserAttributes((error, data) => {
        if (error && error.message === 'User is not authenticated') {
          console.log('error: ', error);
          actions.auth.logout();
          setTimeout(() => {
            window.location.href = '/login';
          }, 1000);
        } else { }
      });
      timeoutId = setTimeout(() => {
        count = 0;
      }, 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isAuthenticated, location?.pathname,cognitoUser,actions.auth, actions.alert, userId]);
  return (
    <Route
      {...rest}
      render={({ history, ...restRouteProps }) =>
        isAuthenticated ? (
          <Component {...restRouteProps} />
        ) : !Object.values(ROUTES).includes(history.location.pathname) ? (
          <Redirect to={ROUTES.LOGIN} />
        ) : null
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  cognitoUser: PropTypes.object
};

export default storeConnector(PrivateRoute, {
  auth: ['cognitoUser', 'email', 'email_verified', "userId"]
});
