import { httpBase } from "./httpBaseUtil";
import { userPool } from "./index";

export const getAccessToken = async () => {
  return new Promise((resolve, reject) => {
    let cognitoUser = userPool.getCurrentUser();
    cognitoUser.getSession((err, result) => {
      if (result) {
        resolve(result.accessToken.jwtToken);
      } else {
        resolve(false);
      }
    });
  });
};

export const fetch = async (endpoint, filters = {}, token) => {
  return httpBase(await getAccessToken()).get(endpoint, {
    params: filters,
  });
};

export const store = async (endpoint, data) => {
  return httpBase(await getAccessToken()).post(endpoint, data);
};

export const update = async (endpoint, data) => {
  return httpBase(await getAccessToken()).put(endpoint, data);
};

export const destroy = async (endpoint, data) => {
  return httpBase(await getAccessToken()).delete(endpoint, data);
};
