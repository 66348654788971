import React, { useRef, useState } from "react";
import ReportViewer from "./ReportViewer";
import "./Report.css";
import { MainTitle } from "../Common";
import { REPORTS_DASHBOARD, FROM, TO } from "../../constant";
import { BACKEND_API } from "../../config";

const Reports = () => {
  const fromTimeInputRef = useRef(null);
  const toTimeInputRef = useRef(null);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  return (
    <div className="reports-page">
      <MainTitle title={REPORTS_DASHBOARD} />
      {/* Date inputs */}
      <div className="controls-container">
        <label>
          {FROM}:
          <input type="date" className="date-picker" ref={fromTimeInputRef} value={fromDate} onChange={handleFromDateChange} onClick={() => fromTimeInputRef.current.showPicker()} />
        </label>
        <label>
          {TO}:
          <input type="date" className="date-picker" ref={toTimeInputRef} value={toDate} onChange={handleToDateChange} onClick={() => toTimeInputRef.current.showPicker()} />
        </label>
      </div>
      {/* Hide a Upload Button */}
        {/* <div className="report-upload-btn">
          <Link to={PRIVATE_ROUTES.REPORT_UPLOAD}>
            <button>
              <span>{UPLOAD}</span>
            </button>
          </Link>
        </div> */}
      <div className="reports-grid">
         <ReportViewer 
          csvUrl={`${BACKEND_API}revenue/report/v3`}
          fromDate={fromDate}
          toDate={toDate}
          field={'time_local'}
          config={{
            title: "Revenue",
            plugin: "Datagrid",
            columns: [
              "Total Revenue",
              "RTM Revenue",
              "RTM Cost",
              "DALMP Revenue",
              "DALMP Settlement",
              "ECRS Revenue",
              "RRS Revenue",
            ],
            group_by: ["date_local"],
            sort: [["date_local", "desc"]],
            expressions: {
              "Total Revenue":
                '"RTM Revenue" + "RTM Cost" + "DALMP Revenue" + "DALMP Settlement" + "ECRS Revenue" + "RRS Revenue"',
            },
          }}
        /> 
        <ReportViewer
          csvUrl={`${BACKEND_API}revenue/report/v3`}
          fromDate={fromDate}
          toDate={toDate}
          field={'Time (CST)'}
          
          config={{
            title: "BESS Throughput and LMP",
            plugin: "Y Line",
            plugin_config: {
              splitMainValues: ["DALMP", "RTLMP"],
            },
            columns: ["BESS MWh", "RTLMP", "DALMP"],
            group_by: ["Time (CST)"],
            sort: [["Time (CST)", "desc"]],
          }}
        />
      </div>
    </div>
  );
};

export default Reports;
