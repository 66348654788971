import React, { useContext,useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PRIVATE_ROUTES } from "../../../constant/routes"
import { Button } from "../index";
import { ThemeContext } from "../Theme/Context";

import {
  
  GO_TO_HOME,
 
  } from "../../../constant/Login"
import { LoginWrapper } from "../Layouts/Layout"
/**
* This component is used to display the home page when an route error occurs. 
  In this case the user will be redirected to the home page and a button will be displayed to go to the home page.
* @return { JSX. Element } The index component to be rendered on the page
*/

const Access = ()  => {

  const [loading,setLoader] = useState(true)


  
  
 
  useEffect(() => {

    // Set a timeout to turn off the loader
    const timeout = setTimeout(() => {
      setLoader(false);
    }, 2000);

    // Cleanup timeout on component unmount
    return () => {
      clearTimeout(timeout);
    };

  },[])

  const { theme } = useContext(ThemeContext); 
  const history = useHistory();

  if(loading){
    return <div></div>
  }

 
  return (
    <LoginWrapper loading={null} theme={theme}>
   
        <div
          className="login-form-column centered"
          style={{ minWidth: "unset" }}
        >
          <div className="title with-underline">
            <h1>
              <span>ERROR</span>
            </h1>
          </div>
          <div className="info" style={{ textAlign: "center" }}>
            <h3>This account does not have permission to accesss this page!</h3>
            <Button
              containerClassName=""
              onClick={() => {
                history.push(PRIVATE_ROUTES.HOME);
              }}
            >
              {GO_TO_HOME}
            </Button>
          </div>
        </div>
  
      
    
    </LoginWrapper>
  );
}

export default Access;




