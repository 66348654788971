export const Style = {
  chart: {
    modelBox: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)",
      zIndex: 250,
    },
    modelGridOTP: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: "#fff",
      borderRadius: "5px",
      zIndex: 1,
      width: "70%",
      maxWidth: "610px",
      maxHeight: "85vh",
      overflowY: "auto",
      boxShadow: "rgba(0, 0, 0, 0.5) 0px 5px 15px",
      "@media (max-width: 768px)": {
        width: "90%",
      },
    },
    dialogTitle: {
      backgroundColor: "var(--peri-color)",
      lineHeight: "normal",
      fontSize: "17px",
      fontWeight: "700",
      borderRadius: "5px 5px 0px 0px",
      textAlign: "left"
    },
    closeIcon: {
      position: "absolute",
      top: "5px",
      right: "8px",
      width: "auto",
    },
    errorMsg: {
      display: "flex",
      justifyContent: "left",
    },
    settingButtonGrid: {
      display: "flex",
      justifyContent: "end",
      marginTop: "30px",
      columnGap: "20px",
    },
    settingSaveButton: {
      background: "var(--peri-color)",
      fontWeight: "normal",
      textTransform: "none",
      padding: "8px 30px",
      width: "auto",
    },
    circularProgress: {
      width: 24,
      height: 24,
      color: "var(--text-color)",
    },
  },
  createAlert: {
    textFieldStyle: {
      width: "100%",
    },
  },
};
