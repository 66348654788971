import React, { createContext, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@mui/material";

export const ThemeContext = createContext();

export const ContextProvider = ({ children }) => {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");

  useEffect(() => {
    document.body.setAttribute("data-theme", theme);
    localStorage.setItem("theme", theme);
  }, [theme]);

  // Dynamic theme creation based on context
  const dynamicTheme = createTheme({
    typography: {
      fontFamily: 'var(--font-default)'
    },
    palette: {
      mode: theme, // "light" or "dark"
      ...(theme === "light"
        ? {
            background: {
              default: "#ffffff",
            },
          }
        : {
            background: {
              default: "#1a1a1a",
            },
            text: {
              primary: "#ffffff",
            },
          }),
    },
  });

  // Memoize the value object to avoid unnecessary renders
  const contextValue = useMemo(() => ({ theme, setTheme }), [theme, setTheme]);

  return (
    <ThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={dynamicTheme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

// Add prop validation
ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
