import React, {
  useEffect,
  useRef,
  useContext,
  useState,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import "@finos/perspective-viewer";
import "@finos/perspective-viewer-datagrid";
import "@finos/perspective-viewer-d3fc";
import "@finos/perspective-viewer/dist/css/themes.css";
import perspective from "@finos/perspective";
import { ThemeContext } from "../Common/Theme/Context";
import "./PerspectiveViewer.css";
import { MainTitle, RefreshButton, PlayStopButton } from "../Common";
import {
  formatLocalDateTime,
  getCurrentDateTime,
  getStartDateTime,
  NO_DATA_FOUND,
  parseLocalDateTime,
  START,
  STOP,
  toUTC,
} from "../../constant";
import { getAccessToken } from "../../services/httpUtil";

const REFRESH_RATE_MS = 60000; // 1 minute

const PerspectiveViewerComponent = ({
  title,
  endpoint,
  requestBodyBuilder,
  configBuilder,
  loading,
  setLoading,
}) => {
  let content;
  const viewerRef = useRef(null);
  const { theme } = useContext(ThemeContext);
  const [isPlaying, setIsPlaying] = useState(false);
  const intervalRef = useRef(null);
  const startTimeInputRef = useRef(null);
  const stopTimeInputRef = useRef(null);

  const [startTime, setStartTime] = useState(getStartDateTime());
  const [stopTime, setStopTime] = useState(getCurrentDateTime());
  const [data, setData] = useState(false);

  const loadPerspective = useCallback(async () => {
    setLoading(true);
    try {
      const requestBody = requestBodyBuilder(toUTC(startTime), toUTC(stopTime));

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getAccessToken()}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      let result = await response.json();
      let data = [];

      if(result && result?.code === 200){
        if (result.data?.length > 0) {
          setLoading(false);
          setData(true);
          data = result.data;
        }
        // Optional data transformation
        if (typeof result.data === "string") {
          data = JSON.parse(result.data);
        }
  
      }

     
      const worker = perspective.worker();
      const table = await worker.table(data);

      const perspectiveTheme = theme === "light" ? "Pro Light" : "Pro Dark";

      const config = configBuilder(perspectiveTheme);

      if (viewerRef.current) {
        await viewerRef.current.load(Promise.resolve(table));
        viewerRef.current.restore(config);
      }
    } catch (error) {
      setLoading(false);
      setData(false);
      console.error("Failed to load data:", error.message);
    }
  }, [
    endpoint,
    setLoading,
    startTime,
    stopTime,
    theme,
    requestBodyBuilder,
    configBuilder,
  ]);

  const handlePlay = () => {
    setIsPlaying(true);

    // Update stopTime and startTime immediately
    setStopTime((prevStopTime) => {
      const newStopTime = getCurrentDateTime();
      const prevStopTimeDate = parseLocalDateTime(prevStopTime);
      const newStopTimeDate = parseLocalDateTime(newStopTime);
      const timeDiffMs = newStopTimeDate - prevStopTimeDate;

      setStartTime((prevStartTime) => {
        const prevStartTimeDate = parseLocalDateTime(prevStartTime);
        const newStartTimeDate = new Date(
          prevStartTimeDate.getTime() + timeDiffMs
        );
        return formatLocalDateTime(newStartTimeDate);
      });

      return newStopTime;
    });

    intervalRef.current = setInterval(() => {
      // Update stopTime and startTime at each interval
      setStopTime((prevStopTime) => {
        const newStopTime = getCurrentDateTime();

        setStartTime((prevStartTime) => {
          const prevStartTimeDate = parseLocalDateTime(prevStartTime);
          const newStartTimeDate = new Date(
            prevStartTimeDate.getTime() + REFRESH_RATE_MS
          );
          return formatLocalDateTime(newStartTimeDate);
        });

        return newStopTime;
      });
    }, REFRESH_RATE_MS);
  };

  const handleStop = () => {
    setIsPlaying(false);
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    loadPerspective();
  }, [loadPerspective]);

  // Clean up interval when component unmounts
  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  const handleDateChange = (setter) => (e) => {
    setter(e.target.value);
  };

  if (loading) {
    content = <div className="loader"></div>;
  } else if (!data) {
    content = <div className="no-data-message">{NO_DATA_FOUND}</div>;
  } else {
    content = <perspective-viewer ref={viewerRef}></perspective-viewer>;
  }

  return (
    <div className={`perspective-viewer-container ${theme}-theme`}>
      <MainTitle classNames="perspective-viewer-title" title={title} />
      <div className="controls-container">
        <label>
          {START}:
          <input
            ref={startTimeInputRef}
            type="datetime-local"
            className="date-time-picker"
            value={startTime}
            onChange={handleDateChange(setStartTime)}
            onClick={() => startTimeInputRef.current?.showPicker()}
          />
        </label>
        <label>
          {STOP}:
          <input
            ref={stopTimeInputRef}
            type="datetime-local"
            className="date-time-picker"
            value={stopTime}
            onChange={handleDateChange(setStopTime)}
            onClick={() => stopTimeInputRef.current?.showPicker()}
          />
        </label>
        <div className="play-stop-btn">
          <RefreshButton onClick={loadPerspective} />
          <PlayStopButton
            isPlaying={isPlaying}
            onPlay={handlePlay}
            onStop={handleStop}
            />
        </div>
      </div>
      <div
        className={`perspective-container ${
          !data ? "perspective-no-data" : ""
        }`}
      > 
        {content}
      </div>
    </div>
  );
};

PerspectiveViewerComponent.propTypes = {
  title: PropTypes.string,
  endpoint: PropTypes.string,
  requestBodyBuilder: PropTypes.func,
  configBuilder: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

export default PerspectiveViewerComponent;
