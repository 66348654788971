import React, { useContext } from "react";
import "./App.css"; // Make sure to import the CSS file
import { Helmet } from "react-helmet";

import { ThemeContext } from "./components/Common/Theme/Context";
import { APP_TITLE } from "./constant";
import {  SnackBarComponent } from "./components/Common";
import MainRoutes from "./MainRoutes";
function App() {
  const { theme } = useContext(ThemeContext);
  return (
    <div className={`App ${theme}-theme`}>
      <Helmet>
        <title>{APP_TITLE}</title>
      </Helmet>
      
      <SnackBarComponent />
      <MainRoutes theme />
    </div>
  );
}

export default App;
