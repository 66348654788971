import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import { ThemeContext } from "../Context";

const ThemeToggle = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  return (
    <button onClick={toggleTheme} className="theme-toggle-button">
      <FontAwesomeIcon    data-tooltip-id="tooltip"
        data-tooltip-content={theme === "dark" ? 'Switch to light theme' : 'Switch to dark theme'}
       icon={theme === "light" ? faMoon : faSun} />
    </button>
  );
};

export default ThemeToggle;
