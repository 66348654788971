export const CDT_TIMEZONE = "America/Chicago";
export const PT_TIMEZONE = "America/Los_Angeles";
export const SERVICE_STATUS = ["Active", "Paused"];

export const StreamConstraints = [
  "SYSTEM_PARAMETERS",
  "DAM_SYSTEM_LAMBDAS",
  "TOTAL_LOAD",
];
export const OPERATOR_ARRAY = [">", "<", "==", ">=", "<="];

export const selectRowsOptions = [10, 20, 50, 100];
export const NO_ALERTS_FOUND = "No alerts at the moment";
export const tableHeaderTitle = ["Report Time", "Alert Description", "Value"];



export const Constraints = {
  //Tab Constraints
  name: "Name",
  selectCondition: "Select condition",
  serviceStatus: "SMS Service",
  value: "Value",
  notification: "Notification (Opt-In) ",
  alerts: "Alerts",
  credits: "Credits",
  alertSelectReadMsg: "Do you want to mark selected alerts as read?",
  alertReadMsg: "Do you want to mark all alerts as read?",
  noAlertReadMsg: "There are no alerts available to mark as read.",
  alertReadSuccess: "All alerts have been marked as read",
  alertReadUnsuccess: "Something went wrong!",
  alertSelectReadSuccess: "The selected alerts have been marked as read.",

  //Create && Edit Chart Constraints
  selectStream: "Select stream",
  selectGridLocation: "Select grid location",
  email: "Email",
  smsAlert: "SMS alert",
  sms: "SMS",
  PAUSED: "PAUSED",
  ACTIVE: "ACTIVE",
  mobileNumberVerify: `Please first set up a phone number here to start SMS Alert service.`,

  //Alert Constraints

  //Validations
  requireStream: "Please select at least one stream",
  requireGridLocation: "Please select at least one grid location",
  requireName: "Name is required",
  requireNameChar: "The name must not exceed 15 characters",
  requireCondition: "Please select at least one condition",
  requirePrice: "Price is required",
  requireServiceStatus: "Please select at least one service status",

  //label Constraints
  createAlert: "Create an Alert",
  editChart: "Edit",
  deleteChart: "Delete",
  addAlert: "Add Alert",
  settingAlert: "Setting Alerts",
  checkBoxAllLabel: "Mark all as read",
  checkBoxSelectLabel: "Mark as read",
  stream: "Stream",
  addPhoneNumber: "Add phone number",
  otpVerification: "OTP Verification",
  verifyNumber: "Verify Your Number",
  otpDetails: "Please enter your mobile number to receive One Time Password",
  sendOTP: "Send OTP",
  verifyCode: "Verification Code",
  verifyOTP: "Verify OTP",
  grid: "Grid",
  alert: "Alert",
  condition: "Condition",
};

export const PhoneConstants = {
  setNotify: {
    success: "OTP has been sent successfully.",
    invalid: "The entered phone number is invalid.",
    internal: "Ops! Something went wrong on our end. Please try again later.",
  },
  setError: {
    invalid: "Please enter a valid phone number with the country code.",
    internal: "An internal server error occurred. Please try again later.",
    validate: "Please enter a valid phone number with the country code.",
  },
};

export const VerifyOTPConstants = {
  setNotify: {
    success: "OTP verified successfully.",
    invalid: "Invalid OTP entered. Please try again.",
    invalidPhone: "The provided phone number is invalid.",
    internal: "An internal server error occurred. Please try again later.",
  },
  setError: {
    invalid: "Invalid OTP entered. Please try again.",
    invalidPhone: "The provided phone number is invalid.",
    internal: "An internal server error occurred. Please try again later.",
    validate: "Invalid OTP format. Please ensure it contains 6 digits and try again.",
  },
};

export const AlertConstants = {
  addAlert: "Alert has been successfully added",
  editTitle: "Edit alert",
  editAlert: "Alert has been successfully modified",
  deleteAlert: "Alert has been successfully removed",
  deleteTitle: "Do you want to remove this alert table?",
};

export const IconColor = {
  green: "#4AA96C",
  red: "#F55C47",
  gray: "#8B8588",
};

/**
 * @desc Generates a message  mobile number.
 * @param {String} string - Register mobile number.
 * @returns {String} A message indicating the Register mobile number.
 */
export const RegisterMobileDes = (string) => {
  if (string && string.length >= 10) {
    const numStr = `SMS alerts will be sent to your number ******${string.slice(
      string.length - 4
    )}. Carrier charges to receive messages may apply.`;
    return numStr;
  } else {
    return `SMS alerts will be sent to your number. Carrier charges to receive messages may apply.`;
  }
};

/**
 * @desc Generates a message indicating that an OTP has been sent to a mobile number for verification.
 * @param {String} string - The mobile number to which the OTP has been sent.
 * @returns {String} A message indicating the OTP verification process.
 */
export const codeDetailsWithNumber = (string) => {
  if (string) {
    const numStr = `OTP has been sent to your mobile number (${string}). Please verify your mobile number`;
    return numStr;
  } else {
    return `OTP has been sent to your mobile number. Please verify your mobile number`;
  }
};
