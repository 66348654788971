import React, { useState, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import {
  Box,
  AppBar,
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Typography,
  Tooltip,
  IconButton,
  Pagination,
  Checkbox,
  FormControlLabel,
  Grid,
  Chip,
} from "@mui/material";
import moment from "moment-timezone";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import {
  CDT_TIMEZONE,
  Constraints,
  selectRowsOptions,
  NO_ALERTS_FOUND,
  tableHeaderTitle,
} from "../../../../constant/Analyst";
import { Style, useTableStyles } from "../../Style";
import { storeConnector } from "../../../../store";
import { AlertCard } from "../../Alert";
import Notification from "../../Notification";
import { formatViewerCount, getStreamName } from "../../Utils";
import { AlertDialog } from "../../../Common";
import { ThemeContext } from "../../../Common/Theme/Context";

function AlertTable({
  alertList,
  alert_prices,
  setIsEdit,
  setIsDelete,
  addAlert,
  isMarkAll,
  setIsMarkAll,
  setIsMarkSelected,
  selectedItems,
  setSelectedItems,
  username,
  usersData,
  userId,
  handelPhoneVerify,
  user_phone_number,
  streams,
  setIsEditing,
  isEditing,
  alertLoading,
  loading,
  userCredit,
  currentPage,
  setCurrentPage,
  alertCount
}) {
  const { theme } = useContext(ThemeContext);
  const classes = useTableStyles();
  const [isOpen, setIsOpen] = useState(true);
  const [isMarkRead, setIsMarkRead] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [openNotification, setOpenNotification] = useState(false);

  const TIMEZONE = CDT_TIMEZONE;

  /**
   * @desc Adds or removes items from the filter based on whether they are checked or not
   * @param {id}
   */
  const handleCheckboxChange = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleDropdownChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  /**
   *@desc Adds CapitalizeText to the end of the string. It trims the first character and converts it to upper case
   *
   * @param {String} name
   * @return {String} name
   */
  const CapitalizeText = (string) => {
    let str = string;
    str = str.trim();
    let correctedStr = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    return correctedStr;
  };

  /**
   *@desc Formats a timestamp to a string.
   *
   * @param {String} timestamp
   * @return {String} date time formatted
   */
  const formatTimestamp = (timestamp) => {
    return moment.tz(timestamp, TIMEZONE).format("DD MMM YYYY HH:mm:ss z");
  };

  /**
   * @desc Creates a description of the object. It is used to show the name stream and grid
   * @param {Object}
   * @returns {String}
   */
  const getDescription = (obj) => {
    try {
      const newObject = JSON.parse(obj);
      let newString =
        CapitalizeText(newObject.alertTitle) +
        " (" +
        getStreamName(newObject.stream, newObject.gridName, streams) +
        " - " +
        newObject.gridName +
        ") value changed";
      return newString;
    } catch (e) {
      console.log("get description error", e);
      return false;
    }
  };

  /**
   * @desc Returns number of alert prices unread. Also sets isDisable property
   * @returns {Number}
   */
  const isDisable = useMemo(() => {
    if (alert_prices?.length === 0) return 0;
    const countIsReadFalse = alert_prices?.filter(
      (item) => !item.isRead
    ).length;
    return countIsReadFalse;
  }, [alert_prices]);

  /**
   * @desc List of alert prices to be displayed with pagination in the table.
   * @returns{Array}
   */
  const tableSeries = useMemo(() => {
    if (alert_prices?.length === 0) return [];
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = alert_prices?.slice(startIndex, endIndex);
    return currentData;
  }, [alert_prices, currentPage, itemsPerPage]);

  /**
   * @desc Calculates the number of pages to display based on the table data
   */
  const totalPages = alert_prices?.length
    ? Math.ceil(alert_prices?.length / itemsPerPage)
    : 0;

  const handleChange = (event) => {
    if (event.target.checked) {
      if (tableSeries === undefined || tableSeries?.length === 0) {
        setIsMarkRead(true);
      } else {
        setIsMarkAll(event.target.checked);
      }
    }
    setIsMarkSelected(false);
    setSelectedItems([]);
  };

  let borderStyle = "";
  if (isOpen) {
    borderStyle = "1px solid var(--chart-border-color)";
  }

  return (
    <Box style={Style.alertTable.box}>
      <Box
        sx={{
          width: isOpen ? "300px" : "0",
          minWidth: isOpen ? "300px" : "0",
          backgroundColor: "var(--background-color)",
          border: borderStyle,
          height: "fit-content",
          marginBottom: "8px",
          boxShadow: "0 0 10px var(--chart-shadow-color)"
        }}
        className="alert-stack"
        style={Style.alertTable.innerBox}
      >
        <AppBar
          position="static"
          elevation={0}
          sx={Style.alertTable.appBar}
        >
          <Toolbar
            sx={[Style.alertTable.toolbar, { color: "var(--text-color)" }]}
          >
            <Box sx={Style.alertTable.flexWidth}>
              <IconButton onClick={toggleDrawer} style={{ width: "auto" }}>
                <ChevronLeftIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>

        {alertLoading || loading || !isOpen ? (
          ""
        ) : (
          <Stack
            direction="column"
            spacing={0}

            style={Style.alertTable.paddingStack}
          >
            {alertList && alertList.length > 0 ? (
              alertList?.map((alert, index) => {
                return (
                  <AlertCard
                    key={alert.id}
                    index={index}
                    alert={alert}
                    Style={Style}
                    CapitalizeText={CapitalizeText}
                    getStreamName={getStreamName}
                    setIsEdit={setIsEdit}
                    setIsDelete={setIsDelete}
                    streams={streams}
                  />
                );
              })
            ) : (
              <>
                <Stack spacing={2} sx={Style.alertTable.noDataTitle}>
                  <Tooltip title={Constraints.addAlert}>
                    <IconButton
                      onClick={() => addAlert()}
                      style={{ width: "auto" }}
                    >
                      <AddIcon fontSize="large" />
                    </IconButton>
                  </Tooltip>
                </Stack>
                <Stack spacing={2} sx={Style.alertTable.noDataTitle}>
                  {NO_ALERTS_FOUND}
                </Stack>
              </>
            )}
          </Stack>
        )}
      </Box>
      <Box style={{ flex: 1 }}>
        <AppBar
          position="static"
          elevation={0}
          sx={[
            Style.alertTable.backgroundWhite,
            {
              backgroundColor: "var(--background-color)",
              border: "1px solid var(--chart-border-color)",
            },
          ]}
        >
          <Toolbar sx={Style.alertTable.mainToolbar}>
            {!isOpen && (
              <Box>
                <IconButton
                  onClick={toggleDrawer}
                  style={{
                    width: "auto",
                    color: "var(--text-color)",
                  }}
                >
                  <ChevronRightIcon />
                </IconButton>
              </Box>
            )}
            <Box
              sx={{ display: "flex", flexWrap: "wrap" }}
              style={Style.alertTable.mainBox}
            >
              <Typography
                sx={{ flexGrow: 1, ml: 1, color: "var(--text-color)" }}
                style={Style.alertTable.headTitle}
              >
                {Constraints.alerts} {alertCount > 0 && `(${formatViewerCount(alertCount)})`}
              </Typography>

              <Stack spacing={2}>
                <Tooltip title={Constraints.addAlert}>
                  <IconButton
                    onClick={() => addAlert()}
                    style={{
                      width: "auto",
                      paddingRight: "10px",
                      color: "var(--text-color)",
                    }}
                  >
                    <AddIcon
                      fontSize="large"
                      style={Style.alertTable.settingAddIcon}
                    />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Chip
                onClick={() => {
                  handelPhoneVerify();
                }}
                label={
                  <span>
                    <Typography
                      component="span"
                      style={Style.alertTable.smsChipColor}
                    >
                      {`${Constraints.credits} : `}
                    </Typography>
                    <Typography
                      component="span"
                      style={
                        userCredit === "0" || userCredit === 0
                          ? Style.alertTable.smsChipColorRed
                          : Style.alertTable.smsChipColor
                      }
                    >
                      {userCredit}
                    </Typography>
                  </span>
                }
                color="primary"
                variant="outlined"
                style={Style.alertTable.smsCredit}
              />
              <Stack spacing={2} style={Style.alertTable.settingIcon}>
                <Tooltip title={Constraints.settingAlert}>
                  <IconButton
                    style={{
                      width: "auto",
                      color: "var(--text-color)",
                    }}
                    onClick={() => {
                      if (user_phone_number !== "") {
                        setOpenNotification(true);
                      } else {
                        handelPhoneVerify();
                      }
                    }}
                    disabled={alertLoading}
                  >
                    <SettingsIcon
                      fontSize="large"
                      style={Style.alertTable.settingAddIcon}
                    />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Box
                className={"alert-checkbox"}
                style={Style.alertTable.headSideBar}
              >
                {selectedItems.length > 0 && (
                  <FormControlLabel
                    control={
                      <IconButton
                        onClick={(e) => {
                          setIsMarkSelected(selectedItems);
                        }}
                      >
                        <DraftsOutlinedIcon />
                      </IconButton>
                    }
                    label={Constraints.checkBoxSelectLabel}
                  />
                )}

                <FormControlLabel
                  style={{ color: "var(--text-color)", marginRight: 3 }}
                  control={
                    <Checkbox
                      checked={isMarkAll}
                      onChange={handleChange}
                      disabled={isDisable === 0}
                      sx={{ color: "var(--text-color)" }}
                    />
                  }
                  label={Constraints.checkBoxAllLabel}
                />
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        {alertLoading || loading ? (
          <Grid
            style={{
              position: "absolute",
              left: "50%",
              marginTop: "10px",
              fontSize: "20px",
              fontWeight: 600,
            }}
          >
            Loading...
          </Grid>
        ) : (
          <Box
            sx={{
              flexGrow: 1,
              mt: 1,
              ml: isOpen ? 1 : 0,
              paddingBottom: "0px",
            }}
          >
            <TableContainer
              className={classes.TableContainer}
              sx={[
                Style.alertTable.TableContainer,
                {
                  backgroundColor: theme === "light" ? "white" : "",
                  boxShadow: "0 0 10px var(--chart-shadow-color)"
                },
              ]}
            >
              <Table className="table" stickyHeader aria-label="sticky table">
                <TableHead className="tableHeader">
                  <TableRow>
                    <TableCell sx={[Style.alertTable.tableCell, {borderLeft: "none"}]}>
                      <span
                        className="title"
                        style={Style.alertTable.cursorPointer}
                      >
                        <Checkbox
                          checked={isMarkAll}
                          onChange={handleChange}
                          disabled={isDisable === 0}
                        />
                      </span>
                    </TableCell>
                    {tableHeaderTitle?.map((item, index) => (
                      <TableCell
                        key={`${item}-${index}`}
                        sx={Style.alertTable.tableCell}
                      >
                        <span
                          className="title"
                          style={Style.alertTable.cursorPointer}
                        >
                        {item}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className="tableBody">
                  {tableSeries?.length > 0 ? (
                    tableSeries?.map((item, index) => (
                      <TableRow
                        key={`${item.id}-${index}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={
                            item?.isRead
                              ? Style.alertTable.tableCellBodyIsRead
                              : Style.alertTable.tableCellBody
                          }
                        >
                          <Checkbox
                            checked={
                              selectedItems.includes(item?.id) || isMarkAll
                            }
                            onChange={(e) => {
                              handleCheckboxChange(item?.id);
                            }}
                          />
                        </TableCell>

                        <TableCell
                          sx={
                            item?.isRead
                              ? Style.alertTable.tableCellBodyIsRead
                              : Style.alertTable.tableCellBody
                          }
                        >
                          {formatTimestamp(new Date(item.reportTime))}
                        </TableCell>

                        <TableCell
                          sx={
                            item?.isRead
                              ? Style.alertTable.tableCellBodyIsRead
                              : Style.alertTable.tableCellBody
                          }
                        >
                         {getDescription(item.description) || "N/A"}
                        </TableCell>
                        <TableCell
                          sx={
                            item?.isRead
                              ? Style.alertTable.tableCellBodyIsRead
                              : Style.alertTable.tableCellBody
                          }
                        >
                          {item.value}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell colSpan={tableHeaderTitle?.length + 1}>
                        <Stack spacing={2} sx={Style.alertTable.noDataTitle}>
                          <Tooltip
                            title={Constraints.addAlert}
                            style={{ width: "auto" }}
                          >
                            <IconButton onClick={() => addAlert()}>
                              <AddIcon fontSize="large" />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                        <Stack spacing={2} sx={Style.alertTable.noDataTitle}>
                          {NO_ALERTS_FOUND}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box spacing={2} sx={Style.alertTable.footerBox}>
              <Box sx={Style.alertTable.paginationBox}>
                {totalPages > 0 && alert_prices.length > itemsPerPage && (
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    size="small"
                    sx={{
                      "& .Mui-selected": {
                        backgroundColor: "var(--peri-color) !important", // change the background color of the selected page
                      },
                    }}
                  />
                )}
              </Box>
              {tableSeries?.length > 0 && (
                <Box sx={Style.alertTable.selectRowsBox}>
                  <label htmlFor="perPageInput">Per page :&nbsp;</label>
                  <select
                    id="perPageInput"
                    value={itemsPerPage}
                    onChange={handleDropdownChange}
                    style={Style.alertTable.selectOption}
                  >
                    {selectRowsOptions?.map((option, index) => (
                      <option key={`${option}-${index}`} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
      {openNotification && user_phone_number && (
        <Notification
          usersData={[]}
          username={username}
          setOpenModal={setOpenNotification}
          openModal={openNotification}
          userId={userId}
          setIsEditing={setIsEditing}
          isEditing={isEditing}
          handelPhoneVerify={handelPhoneVerify}
        />
      )}
      {isMarkRead && (
        <AlertDialog
          isOpen={isMarkRead}
          handleClose={() => {
            setIsMarkAll(false);
            setIsMarkRead(false);
          }}
          handleOkay={() => {
            setIsMarkRead(false);
          }}
          title={Constraints.alert}
          description={Constraints.noAlertReadMsg}
        />
      )}
    </Box>
  );
}

AlertTable.propTypes = {
  alertList: PropTypes.any,
  alert_prices: PropTypes.array,
  setIsEdit: PropTypes.func,
  setIsDelete: PropTypes.func,
  addAlert: PropTypes.func,
  isMarkAll: PropTypes.bool,
  setIsMarkAll: PropTypes.func,
  setIsMarkSelected: PropTypes.func,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
  username: PropTypes.string,
  usersData: PropTypes.array,
  userId: PropTypes.string,
  handelPhoneVerify: PropTypes.func,
  user_phone_number: PropTypes.string,
  streams: PropTypes.array,
  setIsEditing: PropTypes.func,
  isEditing: PropTypes.bool,
  alertLoading: PropTypes.bool,
  loading: PropTypes.bool,
  userCredit: PropTypes.any,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
};

export default storeConnector(AlertTable, {
  service: "all",
  auth: ["userId", "username"],
  alert: ["alertList", "streams", "alertLoading", "alert_prices", "alertCount"],
  user: ["user_phone_number", "userCredit"],
});
