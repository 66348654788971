import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Constraints } from "../../../constant/Analyst";
import { useMenuStyles } from "../Style";
import { ThemeContext } from "../../Common/Theme/Context";

const Index = ({ handleDelete, handleEdit, data, optionsRef, open }) => {
  const { theme } = useContext(ThemeContext);
  const classes = useMenuStyles();
  return (
    <div
      className={classes.options}
      ref={optionsRef}
      style={{
        display: open ? "block" : "none",
        backgroundColor: "var(--background-color)",
        border: theme === "light" ? "" : "1px solid var(--chart-border-color)",
      }}
    >
      <button className={classes.optionItem} onClick={(event) => handleEdit()}>
        {Constraints.editChart}
      </button>
      <button
        className={classes.optionItem}
        onClick={(event) => handleDelete()}
      >
        {Constraints.deleteChart}
      </button>
    </div>
  );
};

Index.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  data: PropTypes.any,
  optionsRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  open: PropTypes.bool.isRequired,
};

export default Index;
