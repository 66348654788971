import { createBrowserHistory } from 'history';

const serviceReducer = (
  state = {
    currentPath: '',
    tmpNotifications: [],
    history: createBrowserHistory(),
    loading: false,
    loaders: {}
  },
  action
) => {
  const handlers = {
    UPDATE_SERVICE_STATE: () => ({
      ...state,
      ...action
    }),
    LOADING: () => {
      const newState = { ...state };
      const loaderName = Object.keys(action)[0];
      if (action[loaderName]) {
        newState.loaders[loaderName] = true;
      } else {
        delete state.loaders[loaderName];
      }
      if (Object.keys(state.loaders).length) {
        newState.loading = true;
      } else {
        newState.loading = false;
      }
      return newState;
    }
  };
  if (!handlers[action.type]) {
    return state;
  }
  return handlers[action.type]();
};

export default serviceReducer;
