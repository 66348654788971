import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "../..";

const LoginWrapper = ({ children, loading }) => (
  <div
    className="login-box"
    style={{
      zIndex: 50,
    }}
  >
    {loading && <Spinner type="absolute dark" />}
    <div className="login-glass">{children}</div>
  </div>
);

export default LoginWrapper;

LoginWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  loading: PropTypes.bool,
};
