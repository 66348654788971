import React from "react";
import PropTypes from "prop-types";
import { Header } from "./Layout";

const Index = ({ theme,isAuthenticated,logout }) => {
  return (
      <Header  isAuthenticated={isAuthenticated} theme={theme} />
  );
};

Index.propTypes = {
  theme: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool, 
  logout: PropTypes.func, 
};

export default Index;
