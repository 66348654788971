import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from 'prop-types';
import { storeConnector } from '../../store';
const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children, actions, authLoading, cognitoUser }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(cognitoUser?.username);
  const [auth, setAuth] = useState(false);
  const loading = true;

  useEffect(() => {
    actions.auth.initUser();
  }, [actions]);

  useEffect(() => {
    if (cognitoUser) {
      let auth;
      cognitoUser.getSession((error, session) => {
        if (session && !error) {
          let currentAccessToken = session?.idToken;
          auth = true;

          if (currentAccessToken.payload) {
            auth = currentAccessToken.payload['email_verified'];
          } else {
            auth = false;
          }
        } else {
          auth = false;
        }
      });
      if(auth){
        setAuth(cognitoUser);
        setIsAuthenticated(auth);
      }
    }
  }, [cognitoUser]);

  // Wrap logout function in useCallback to memoize it
  const logout = useCallback(async () => {
    actions.auth.logout();
  }, [actions.auth]);

  // Wrap the value object in useMemo to avoid unnecessary re-renders
  const value = useMemo(() => ({
    isAuthenticated,
    auth,
    loading,
    logout,
  }), [isAuthenticated, auth, loading, logout]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
  actions: PropTypes.shape({
    auth: PropTypes.shape({
      initUser: PropTypes.func.isRequired,
      logout: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
  authLoading: PropTypes.bool.isRequired,
  cognitoUser: PropTypes.any,
};

export default storeConnector(AuthProvider, {
  service: 'all',
  auth: ['authLoading', 'username', 'cognitoUser'],
});