import React, { useState, useCallback } from "react";
import PerspectiveViewerComponent from "./PerspectiveViewer";
import { SITE_DATA } from "../../constant";
import { BACKEND_API } from "../../config";

const EMSDataViewer = () => {
  const [loading, setLoading] = useState(true);

  // Memoize requestBodyBuilder to avoid unnecessary re-creations
  const requestBodyBuilder = useCallback(
    (startTimeUTC, stopTimeUTC) => ({
      startTime: startTimeUTC,
      stopTime: stopTimeUTC,
      measurements: ["site"],
      pointNames: ["BESSkW", "AllowedMaxP", "AllowedMinP", "SOC"],
    }),
    []
  );

  // Memoize configBuilder to avoid unnecessary re-creations
  const configBuilder = useCallback(
    (perspectiveTheme) => ({
      title: "Fractal EMS",
      plugin: "Y Line",
      group_by: ["Time"],
      split_by: ["PointName"],
      columns: ["Value"],
      filter: [],
      sort: [["Time", "desc"]],
      settings: false,
      aggregates: {
        Value: "mean",
        PointName: "any",
      },
      theme: perspectiveTheme,
    }),
    []
  );

  return (
    <PerspectiveViewerComponent
      title={SITE_DATA}
      endpoint={`${BACKEND_API}fractal-ems/matta1`}
      requestBodyBuilder={requestBodyBuilder}
      configBuilder={configBuilder}
      loading={loading}
      setLoading={setLoading}
    />
  );
};

export default EMSDataViewer;
